import React, { useEffect, useState } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import { AuthLayout, NonAuthLayout } from "../components/Layout/PageLayout";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./routes";

const PublicRouteWrapper = () => {
  const routes = useRoutes(PUBLIC_ROUTES);
  return routes;
};

const PrivateRouteWrapper = () => {
  const routes = useRoutes(PRIVATE_ROUTES);
  return routes;
};

const Pages = () => {
  const user = sessionStorage.getItem("user");
  const location = useLocation();
  const userData = JSON.parse(user);
  const { pathname } = useLocation();

  const [acc, setAcc] = useState(null);
  const [accs, setAccs] = useState(null);
  const [users, setUsers] = useState();

  useEffect(() => {
    const bus_account_type = sessionStorage.getItem("bus_account_type");
    const account_type = sessionStorage.getItem("account_type");
    const reg_user = sessionStorage.getItem("reg_user");
    setAcc(bus_account_type);
    setAccs(account_type);
    setUsers(reg_user);
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/create-account/step-1") {
      sessionStorage.removeItem("account_type");
      sessionStorage.removeItem("bus_account_type");
      setAcc(null);
      setAccs(null);
    } else if (pathname === "/create-account/step-2") {
      sessionStorage.removeItem("bus_account_type");
      setAcc(null);
      setAccs(null);
    }
  }, [pathname]);

  return (user && userData?.data?.userType) ||
    (user && users) ||
    (user && (acc || accs)) ? (
    <AuthLayout>
      <PrivateRouteWrapper key={location.pathname} />
    </AuthLayout>
  ) : user && (!acc || !accs) ? (
    <NonAuthLayout>
      <PrivateRouteWrapper key={location.pathname} />
    </NonAuthLayout>
  ) : (
    <NonAuthLayout>
      <PublicRouteWrapper key={location.pathname} />
    </NonAuthLayout>
  );
};

export default Pages;
