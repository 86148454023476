import axios from 'axios';
import * as API from '../url';
import axiosInstance from '../axiosInstance';

export const login = async (body) => {
  const res = await axios.post(API.LOGIN, body);

  return res.data;
};

export const signUp = async (body) => {
  const res = await axios.post(API.SIGN_UP, body);
  return res.data;
};

export const getCountries = async () => {
  const res = await axios.get(API.COUNTRIES);
  return res.data;
};

export const sendEmailToken = async (body) => {
  const res = await axiosInstance.post(API.SEND_EMAIL_TOKEN, body);
  return res.data;
};

export const verifyEmail = async (body) => {
  const res = await axiosInstance.put(API.VERIFY_EMAIL, body);
  return res.data;
};

export const createPassword = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PASS, body);
  return res.data;
};

export const updatePassword = async (body) => {
  const res = await axiosInstance.patch(API.UPDATE_PASSWORD, body);
  return res.data;
};

export const changePasswordOtp = async (body) => {
  const res = await axiosInstance.patch(API.CHANGE_PASSWORD_OTP, body);
  return res.data;
};

export const changePinOtp = async (body) => {
  const res = await axiosInstance.patch(API.CHANGE_PIN_OTP, body);
  return res.data;
};

export const updateTransactionPin = async (body) => {
  const res = await axiosInstance.patch(API.UPDATE_TRANSACTION_PIN, body);
  return res.data;
};

export const logout = async (body) => {
  const res = await axiosInstance.post(API.LOGOUT, body);

  return res.data;
};
