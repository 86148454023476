import React, { useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { IoIosArrowDown, IoMdMenu } from 'react-icons/io';
import { Image, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import BusinessSideDrawer from './BusinessSideDrawer';
import { Main } from '../../data/Notification/Main';
import { useGetFlags, useGetUser } from '../../../services/query/account';
import LLCSideDrawer from './LLCSideDrawer';
import NGOSideDrawer from './NGOSideDrawer';
import FreelancerSideDrawer from './FreelancerSideDrawer';
import SetupSideDrawer from './SetupSideDrawer';
import SideDrawer from './SideDrawer';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isMobile] = useMediaQuery('(max-width: 991px)');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data } = useGetUser();

  const { pathname } = useLocation();
  const account_type = sessionStorage.getItem('account_type');
  const bus_account_type = sessionStorage.getItem('bus_account_type');
  const { data: flags, refetch } = useGetFlags();

  useEffect(() => {
    refetch();
  }, [pathname]);

  const ngoChecks =
    flags?.isUnregisteredBusinessDetailsSet &&
    flags?.isUnregisteredDirectorInfoSet &&
    flags?.isShareAlloted &&
    flags?.isSecretaryAdded &&
    flags?.isWitnessAdded &&
    flags?.isUnregisteredBusinessPaymentCompleted &&
    flags?.isPinSet &&
    data?.data?.businessRegistration?.status !== 'IN_REVIEW';

  const llcChecks =
    flags?.isUnregisteredBusinessDetailsSet &&
    flags?.isUnregisteredDirectorInfoSet &&
    flags?.isShareAlloted &&
    flags?.isSecretaryAdded &&
    flags?.isWitnessAdded &&
    flags?.isUnregisteredBusinessPaymentCompleted &&
    flags?.isPinSet &&
    data?.data?.businessRegistration?.status !== 'IN_REVIEW';

  const unregBusCheck =
    flags?.isUnregisteredBusinessDetailsSet &&
    flags?.isUnregisteredDirectorInfoSet &&
    flags?.isUnregisteredBusinessProfileVerified &&
    flags?.isUnregisteredBusinessPaymentCompleted &&
    flags?.isPinSet &&
    data?.data?.businessRegistration?.status !== 'IN_REVIEW';

  const freelancerChecks =
    flags?.isBusinessDetailsSet && flags?.isBvnSet && flags?.isPinSet;

  const regCheck =
    flags?.isBusinessDetailsSet &&
    flags?.isBusinessOwnerSet &&
    flags?.isBusinessProfileVerified &&
    flags?.isBvnSet &&
    (flags?.canAddOtherDirectors
      ? flags?.isDirectorDetailsSet
      : !flags?.isDirectorDetailsSet) &&
    (flags?.isSignatorySkipped
      ? !flags?.isSignatorySet
      : flags?.isSignatorySet) &&
    flags?.isPinSet;

  return (
    <Flex
      flexDirection="column"
      bg="darkBg"
      pos="fixed"
      w="100%"
      zIndex="1000"
      py={isMobile ? '30px' : '25px'}
      color="#fff"
    >
      <Flex
        justifyContent="space-between"
        align="center"
        pl={isMobile ? '20px' : '320px'}
        pr={isMobile ? '20px' : '48px'}
        w="full"
      >
        {isMobile && (
          <Flex align="center" w="full" justifyContent="space-between">
            <Box w="full">
              <Flex gap="16px" align="center">
                <Box>
                  <Text fontSize="13px">{`${data?.data?.firstName || ''} ${
                    data?.data?.lastName || ''
                  }`}</Text>
                  <Text
                    fontSize="11px"
                    mt="5px"
                    textTransform="capitalize"
                    color="orangeBg"
                  >
                    {data?.data?.userType === 'FREELANCE'
                      ? 'Freelancer Account'
                      : data?.data?.userType === 'REGISTERED'
                      ? 'Business Account'
                      : data?.data?.userType?.toLowerCase()}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                color="#BDBDBD"
                borderRadius="20px"
                border="1px solid rgba(104, 132, 202, 0.5)"
                p="7px"
                onClick={onOpen}
                w="fit-content"
                ml={isMobile ? '25px' : '320px'}
                cursor="pointer"
              >
                <IoMdMenu size="20px" />
              </Flex>
            </Box>
          </Flex>
        )}

        {!isMobile && (
          <Flex align="center" gap="18px" w="100%" justifyContent="flex-end">
            <Box>
              <Flex
                border="1px solid rgba(104, 132, 202, 0.30)"
                rounded="full"
                align="center"
                justifyContent="center"
                w="40px"
                h="40px"
              >
                <Image src={'/assets/sms.svg'} w="20px" h="20px" />
              </Flex>
            </Box>

            <Main />
            <Flex justifyContent="space-between" w="14rem" align="center">
              <Flex gap="16px" align="center">
                <Flex
                  border="0px 4px 10px 0px rgba(83, 83, 83, 0.23)"
                  rounded="full"
                  align="center"
                  bg="#e5eeff"
                  justifyContent="center"
                  w={data?.data?.avatar ? '' : '40px'}
                  h={data?.data?.avatar ? '' : '40px'}
                >
                  <Image
                    objectFit="contain"
                    src={data?.data?.avatar || '/assets/sms.svg'}
                    rounded={data?.data?.avatar ? 'full' : 'unset'}
                    w={data?.data?.avatar ? '40px' : '20px'}
                    h={data?.data?.avatar ? '40px' : '20px'}
                  />
                </Flex>
                <Box>
                  <Text fontSize="13px">{`${data?.data?.firstName || ''} ${
                    data?.data?.lastName || ''
                  }`}</Text>
                  <Text
                    fontSize="11px"
                    textTransform="capitalize"
                    color="orangeBg"
                  >
                    {data?.data?.userType === 'FREELANCE'
                      ? 'Freelancer Account'
                      : data?.data?.userType === 'REGISTERED'
                      ? 'Business Account'
                      : data?.data?.userType?.toLowerCase()}
                  </Text>
                </Box>
              </Flex>

              <IoIosArrowDown color="#B4B4B4" />
            </Flex>
          </Flex>
        )}
      </Flex>

      <Box>
        {(data?.data?.userType === 'UNREGISTERED' &&
          data?.data?.business?.businessType === 'BUSINESS-NAME' &&
          !unregBusCheck) ||
        (account_type &&
          account_type.includes('Don’t') &&
          bus_account_type &&
          bus_account_type.includes('business')) ? (
          <BusinessSideDrawer isOpen={isOpen} onClose={onClose} flags={flags} />
        ) : (data?.data?.userType === 'REGISTERED' && !regCheck) ||
          (account_type &&
            account_type.includes('Business') &&
            !bus_account_type) ? (
          <FreelancerSideDrawer
            flags={flags}
            isOpen={isOpen}
            onClose={onClose}
          />
        ) : (data?.data?.userType === 'UNREGISTERED' &&
            data?.data?.business?.businessType ===
              'LIMITED-LIABILITY-COMPANY' &&
            !llcChecks) ||
          (account_type &&
            account_type.includes('Don’t') &&
            bus_account_type &&
            bus_account_type.includes('llc')) ? (
          <LLCSideDrawer isOpen={isOpen} flags={flags} onClose={onClose} />
        ) : (data?.data?.userType === 'UNREGISTERED' &&
            data?.data?.business?.businessType === 'NGO' &&
            !ngoChecks) ||
          (account_type &&
            account_type.includes('Don’t') &&
            bus_account_type &&
            bus_account_type.includes('ngo')) ? (
          <NGOSideDrawer isOpen={isOpen} flags={flags} onClose={onClose} />
        ) : (data?.data?.userType === 'FREELANCE' && !freelancerChecks) ||
          (account_type && account_type.includes('Freelance')) ? (
          <FreelancerSideDrawer
            isOpen={isOpen}
            flags={flags}
            onClose={onClose}
          />
        ) : (
          <SideDrawer isOpen={isOpen} onClose={onClose} />
        )}
      </Box>
    </Flex>
  );
};

export default Header;
