import axiosInstance, { uploadInstance } from '../axiosInstance';
import * as API from '../url';

export const sendPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.SEND_PASS_OTP, body);
  return res.data;
};

export const reSendPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.RESEND_PASS_OTP, body);
  return res.data;
};

export const verifyPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_PASS_OTP, body);
  return res.data;
};

export const changePass = async (body) => {
  const res = await axiosInstance.put(API.CHANGE_PASS, body);
  return res.data;
};

export const registerFreelance = async (body) => {
  const res = await axiosInstance.post(API.REGISTER_FREELANCE, body);
  return res.data;
};

export const ownerDetails = async (body) => {
  const res = await uploadInstance.post(API.OWNER_DETAILS, body);
  return res.data;
};

export const getUser = async () => {
  const res = await axiosInstance.get(API.GET_USER);
  return res.data;
};

export const getFlags = async () => {
  const res = await axiosInstance.get(API.GET_FLAGS);
  return res.data;
};

export const utilityBill = async (body) => {
  const res = await uploadInstance.post(API.UTILITY_BILL, body);
  return res.data;
};

export const createPin = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PIN, body);
  return res.data;
};

export const verifyProfile = async (body) => {
  const res = await uploadInstance.post(API.VERIFY_PROFILE, body);
  return res.data;
};
export const addSignatories = async (body) => {
  const res = await axiosInstance.post(API.ADD_SIGNATORY, body);
  return res.data;
};
export const registerBusiness = async (body) => {
  const res = await uploadInstance.post(API.REGISTER_BUSINESS, body);
  return res.data;
};
export const addBusinessOwnerInfo = async (body) => {
  const res = await uploadInstance.put(API.ADD_OWNER_INFO, body);
  return res.data;
};
export const addOtherDirectors = async (body) => {
  const res = await uploadInstance.put(API.ADD_DIRECTOR_DETAILS, body);
  return res.data;
};
export const createSubAccount = async (body) => {
  const res = await axiosInstance.post(API.CREATE_SUB_ACCOUNT, body);
  return res.data;
};
export const createTransactionPin = async (body) => {
  const res = await axiosInstance.post(API.CREATE_TRANSACTION_PIN, body);
  return res.data;
};
export const sendBvnOtp = async (body) => {
  const res = await axiosInstance.post(API.SEND_BVN_OTP, body);
  return res.data;
};

export const verifyBvnOtp = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_BVN_OTP, body);
  return res.data;
};

export const reSendBvnOtp = async (body) => {
  const res = await axiosInstance.post(API.RESEND_BVN_OTP, body);
  return res.data;
};
export const verifyBvnPin = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_BVN_PIN, body);
  return res.data;
};
export const resendBvnOtp = async (body) => {
  const res = await axiosInstance.post(API.RESEND_BVN_OPT, body);
  return res.data;
};

export const freezeCard = async (body) => {
  const res = await axiosInstance.patch(API.FREEZE_CARD, body);
  return res.data;
};

export const fundDollar = async (body) => {
  const res = await axiosInstance.post(API.FUND_DOLLAR, body);
  return res.data;
};

export const fundNaira = async (body) => {
  const res = await axiosInstance.post(API.FUND_NAIRA, body);
  return res.data;
};

export const unFreezeCard = async (body) => {
  const res = await axiosInstance.patch(API.UN_FREEZE_CARD, body);
  return res.data;
};

export const getProfile = async (body) => {
  const res = await axiosInstance.get(API.GET_PROFILE, body);
  return res.data;
};

export const updateUser = async (body) => {
  const res = await axiosInstance.patch(API.UPDATE_USER, body);
  return res.data;
};

export const uploadAvatar = async (body) => {
  const res = await uploadInstance.post(API.UPLOAD_AVATAR, body);
  return res.data;
};

export const createVirtualAccount = async (body) => {
  const res = await axiosInstance.post(API.CREATE_VIRTUAL_ACCOUNT, body);
  return res.data;
};

export const addNoOfSignatories = async (body) => {
  const res = await axiosInstance.patch(API.ADD_NO_OF_SIGNATORIES, body);
  return res.data;
};

export const addNoOfDirectors = async (body) => {
  const res = await axiosInstance.patch(API.ADD_NO_OF_DIRECTORS, body);
  return res.data;
};

export const getCardDetails = async (body) => {
  const res = await axiosInstance.post(API.CARD_DETAILS, body);
  return res.data;
};

export const getFlow = async ({ queryKey }) => {
  const [, duration] = queryKey;
  const res = await axiosInstance.get(`${API.GET_FLOW}=${duration}`);
  return res.data;
};

export const getUserAccount = async (body) => {
  const res = await axiosInstance.get(API.GET_USER_ACCOUNT, body);
  return res.data;
};

export const getPreviewInvoice = async (query) => {
  const res = await axiosInstance.get(`${API.GET_INVOICE_PREVIEW}/${query}`);
  return res.data;
};

export const deleteAccount = async (body) => {
  const res = await axiosInstance.post(API.DELETE_ACCOUNT, body);
  return res.data;
};

export const getAccountTiers = async () => {
  const res = await axiosInstance.get(API.GET_ACCOUNT_TIERS);
  return res.data;
};

export const accountTierUpgrade = async (body) => {
  const res = await uploadInstance.patch(API.ACCOUNT_TIER_UPGRADE, body);
  return res.data;
};

export const sendNinOtp = async (body) => {
  const res = await axiosInstance.post(API.SEND_NIN_OTP, body);
  return res.data;
};

export const verifyNinOtp = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_NIN_OTP, body);
  return res.data;
};
export const resendNinOtp = async (body) => {
  const res = await axiosInstance.post(API.RESEND_NIN_OTP, body);
  return res.data;
};
export const getWalletBalance = async (query) => {
  const res = await axiosInstance.get(API.GET_WALLET_BALANCE(query.id));
  return res.data;
};
