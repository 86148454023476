import * as API from '../url';
import axiosInstance, { uploadInstance } from '../axiosInstance';

export const createPaymentLinkSettings = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PAYMENT_LINK_SETTINGS, body);
  return res.data;
};

export const updatePaymentLinkSettings = async (body) => {
  const res = await uploadInstance.patch(
    API.UPDATE_PAYMENT_LINK_SETTINGS,
    body
  );
  return res.data;
};

export const getPaymentLinkSettings = async () => {
  const res = await axiosInstance.get(API.GET_PAYMENT_LINK_SETTINGS);
  return res.data;
};

export const createPaymentLink = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PAYMENT_LINK, body);
  return res.data;
};

export const getPaymentLinkTotal = async (query) => {
  const res = await axiosInstance.get(
    API.GET_PAYMENT_LINK_TOTAL(query.id, query.status, query.reference)
  );
  return res.data;
};

export const getPaymentLinkCount = async (query) => {
  const res = await axiosInstance.get(API.GET_PAYMENT_LINK_COUNT(query));
  return res.data;
};

export const viewCustomerPaymentLink = async (query) => {
  const res = await axiosInstance.get(
    `${API.VIEW_CUSTOMER_PAYMENT_LINK}/${query}`
  );
  return res.data;
};

export const getAllDynamicPayments = async ({ queryKey }) => {
  const [, accountId, page, limit, startDate, endDate] = queryKey;

  let query = '';

  if (accountId) {
    query += `accountId=${accountId}&`;
  }
  if (page) {
    query += `page=${page}&`;
  }
  if (limit) {
    query += `limit=${limit}&`;
  }
  if (startDate) {
    query += `startDate=${startDate}&`;
  }
  if (endDate) {
    query += `endDate=${endDate}&`;
  }
  // if (status) {
  //   query += `status=${status}`;
  // }

  if (query.endsWith('&')) {
    query = query.slice(0, -1);
  }

  const res = await axiosInstance.get(
    `${API.GET_DYNAMIC_PAYMENT_LIST}?${query}`
  );

  return res.data;
};

export const getLinkDetails = async (query) => {
  const res = await axiosInstance.get(API.GET_LINK_DETAILS(query.uuid));
  return res.data;
};

export const updatePaymentLinkStatus = async (body) => {
  const res = await axiosInstance.patch(API.UPDATE_PAYMENT_LINK_STATUS, body);
  return res.data;
};

export const viewCustomerGeneralPaymentLink = async (query) => {
  const res = await axiosInstance.get(
    `${API.VIEW_CUSTOMER_GENERAL_PAYMENT_LINK}/${query}`
  );
  return res.data;
};

export const initiatePaymentOrder = async (body) => {
  const res = await axiosInstance.post(API.INITIATE_PAYMENT_ORDER, body);
  return res.data;
};

export const initiateGeneralPaymentOrder = async (body) => {
  const res = await axiosInstance.post(
    API.INITIATE_GENERAL_PAYMENT_ORDER,
    body
  );
  return res.data;
};

export const getGeneralPaymentLink = async () => {
  const res = await axiosInstance.post(API.GET_GENERAL_PAYMENT_LINK);
  return res.data;
};

export const confirmPaymentLinkOrder = async (body) => {
  const res = await axiosInstance.post(API.CONFIRM_PAYMENT_LINK_ORDER, body);
  return res.data;
};

export const getPaymentSettings = async () => {
  const res = await axiosInstance.get(API.PAYMENT_SETTINGS);
  return res.data;
};
