import React from "react";
import {
  Flex,
  Modal,
  ModalBody,
  Box,
  ModalContent,
  ModalOverlay,
  Text,
  ModalHeader,
  CloseButton,
  Button,
  Textarea,
} from "@chakra-ui/react";

const ReportTrans = ({ isOpen, onClose, setOpenPayment }) => {
  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay
        backdropFilter="auto"
        backdropBlur="2px"
        paddingLeft="20px"
      />{" "}
      <ModalContent
        overflowY="auto"
        borderRadius="8px"
        color="#000"
        pt="16px"
        pb="40px"
        bg="#fff"
        mx={{ base: "10px", md: "" }}
      >
        <ModalHeader
          borderBottom="1px solid #E7E8EA"
          px="24px"
          pt="20px"
          pb="16px"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize={{ base: "18px", md: "20px" }}
              color="#2463EB"
              fontWeight={500}
            >
              Report Transaction
            </Text>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="20px"
              h="20px"
              color="#333"
              rounded="full"
              border="2px solid #B7B9BF"
              onClick={() => {
                setOpenPayment(true);
                onClose();
              }}
            >
              <CloseButton color="#333333" fontSize="8px" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody p="0">
          <Box px="24px" pt="32px" pb="40px" h="224px">
            <Textarea
              h="full"
              bg="transparent"
              placeholder="Enter complaints"
              _placeholder={{ color: "#BDBDBD", fontStyle: "italic" }}
              border="1px solid rgba(123, 71, 204, 0.30)"
              borderRadius="10px"
            />
          </Box>
          <Flex flexDir="column" alignItems="center" gap="8px" pb="50px">
            <Text color="#4F4F4F" fontSize="14px">
              Do you want to speak to an agent?
            </Text>
            <Text color="#2463EB">Contact Support</Text>
          </Flex>
          <Box px="24px">
            <Button
              h="56px"
              borderRadius="8px"
              w="full"
              onClick={() => {
                onClose();
              }}
            >
              Submit
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ReportTrans;
