import { RxLink1 } from 'react-icons/rx';
import DetailCards from '../../components/data/PaymentLink/DetailCards';
import { Button, Flex, Text } from '@chakra-ui/react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import SingleTransactionTableLayer from '../../components/data/PaymentLink/SingleTransactionTableLayer';
import GenerateLinkDrawer from '../../components/modals/GenerateLinkDrawer';
import GenerateLinkSuccess from '../../components/modals/GenerateLinkSuccess';
import { useState, useEffect } from 'react';
import {
  useGetLinkDetails,
  useGetPaymentLinkSettings,
  useGetPaymentSettings,
} from '../../services/query/paymentLink';

const TransactionDetails = () => {
  const { uuid, reference } = useParams();

  const { mutate, data: linkData, isLoading } = useGetLinkDetails();

  useEffect(() => {
    mutate({
      uuid: uuid,
    });
  }, []);

  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = useState(false);

  const [paymentLink, setPaymentLink] = useState('');
  const { data: paymentLinkFee } = useGetPaymentSettings();

  const { data: settings, isLoading: isSettings } = useGetPaymentLinkSettings();

  return (
    <div>
      <Flex
        justifyContent={'space-between'}
        alignContent={'center'}
        border="1px solid #F2F2F2"
        bg="#fff"
        overflow="hidden"
        w={'full'}
        h={'88px'}
        px={'10px'}
      >
        <Flex my={'auto'}>
          <Flex
            onClick={() => {
              navigate(-1);
            }}
            alignItems="center"
            color="#0F172B"
            cursor="pointer"
            mr={'10px'}
          >
            <IoIosArrowBack cursor="pointer" fontSize="20px" />
            <Text>Back</Text>
          </Flex>
          <Text
            fontWeight={500}
            fontSize={'24px'}
            color={'#0F172B'}
            ml={'15px'}
            display={{ base: 'none', md: 'flex' }}
          >
            Payment Link
          </Text>
        </Flex>
        <Button
          fontSize={'16px'}
          fontWeight={600}
          lineHeight={'24px'}
          py="14px"
          px="15px"
          gap="10px"
          my={'auto'}
          display={{ base: 'none', md: 'flex' }}
          onClick={() => {
            setIsShow(true);
          }}
        >
          <RxLink1 fontSize="20px" color="#F3C948" />

          <Text> Create Unique Link</Text>
        </Button>
      </Flex>
      <DetailCards
        uuid={uuid}
        linkDetails={linkData?.data}
        isLoading={isLoading}
        isSettings={isSettings}
        settings={settings}
        reference={reference}
      />
      <SingleTransactionTableLayer
        reference={reference}
        linkDetails={linkData?.data}
      />
      <GenerateLinkDrawer
        isOpen={isShow}
        setOpen={setOpen}
        setPaymentLink={setPaymentLink}
        paymentLinkFee={paymentLinkFee}
        settings={settings}
        onClose={() => {
          setIsShow(false);
        }}
      />
      <GenerateLinkSuccess
        isOpen={open}
        onClose={() => setOpen(false)}
        paymentLink={paymentLink}
      />
    </div>
  );
};

export default TransactionDetails;
