export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN = `${BASE_URL}auth/login`;
export const SIGN_UP = `${BASE_URL}auth/signup`;
export const COUNTRIES = 'https://ipapi.co/json/';
export const VERIFY_EMAIL = `${BASE_URL}auth/verify-email`;
export const SEND_EMAIL_TOKEN = `${BASE_URL}auth/send-email-token`;
export const REQUEST_VIRTUAL_CARD = 'card/request-virtual-card';
export const GET_CARDS = 'card/get-user-cards';
export const SEND_PASS_OTP = 'auth/send-password-reset';
export const VERIFY_PASS_OTP = 'auth/verify-reset-password';
export const CHANGE_PASS = 'auth/update-password';
export const LOGOUT = 'auth/logout';
export const REGISTER_BUSINESS = '/business/register-business';
export const ADD_OWNER_INFO = '/business/add-ownership-info';
export const RESEND_PASS_OTP = 'auth/resend-password-reset';
export const VERIFY_PROFILE = '/business/profile-verification';
export const CREATE_INVOICE = '/invoice/create-invoice-draft';
export const ADD_DIRECTOR_DETAILS = '/business/add-other-directors';
export const ADD_SIGNATORY = '/business/add-signatory';
export const CREATE_SUB_ACCOUNT = '/business/create-subAccount';
export const CREATE_TRANSACTION_PIN = 'auth/create-transaction-pin';
export const SEND_BVN_OTP = 'verification/send-verify-bvn-otp';
export const VERIFY_BVN_PIN = '/verification/verify-bvn-with-otp';
export const RESEND_BVN_OPT = '/verification/resend-verify-bvn-otp';
export const CREATE_PASS = 'auth/create-user-password';
export const REGISTER_FREELANCE = 'business/register-freelance-account';
export const OWNER_DETAILS = 'business/add-freelance-owner-details';
export const GET_USER = 'users/profile';
export const GET_FLAGS = 'users/registration-flags';
export const UTILITY_BILL = 'business/upload-utility-bill';
export const CREATE_PIN = 'auth/create-transaction-pin';
export const VERIFY_BVN_OTP = 'verification/verify-bvn-with-otp';
export const RESEND_BVN_OTP = 'verification/resend-verify-bvn-otp';
export const COUNTRIES_STATES =
  'https://countriesnow.space/api/v0.1/countries/states';
export const GET_RECENT_TRANSACTIONS = 'account/recent-transactions';
export const GET_ALL_TRANSACTIONS = 'account/transactions';
export const GET_PROFILE = '/users/profile';
('https://countriesnow.space/api/v0.1/countries/states');
export const UPDATE_USER = 'users/update';
export const UPLOAD_AVATAR = 'business/update-avatar';
export const UPDATE_PASSWORD = 'auth/update-password';
export const CHANGE_PASSWORD_OTP = 'auth/change-password-otp';
export const TIMEZONE_REGIONS =
  'https://gist.github.com/jrnk/8eb57b065ea0b098d571.js';
export const CHANGE_PIN_OTP = 'auth/change-pin-otp';
export const UPDATE_TRANSACTION_PIN = 'auth/update-pin';
export const BUY_AIRTIME = '/bill/purchase-airtime';
export const GET_PHONE_NETWORK = (number = '') =>
  `/bill/get-phone-network/${number}`;
export const DEL_BENEFICIARY = (id = '') => `/beneficiary/${id}`;
export const BUY_DATA = '/bill/purchase-data';
export const BUY_ELECTRICITY = '/bill/purchase-electricity';
export const BUY_CABLE_TV = '/bill/purchase-cable';
export const METER = '/bill/validate-biller-input-field-for-electricity';
export const CABLE = '/bill/validate-biller-input-field-for-cable';
export const ACC_NAME = (accountNumber = '', bankCode = '') =>
  `account/resolve-bank-name?accountNumber=${accountNumber}&bankCode=${bankCode}`;
export const GET_BANKS = '/account/get-bank-list?limit=500&page=1';
export const SINGLE_TRANSFER = '/account/single-transfer';
export const BIZFLEX_TRANSFER = '/account/bizflex-transfer';
export const BIZFLEX_PHONE_TRANSFER = '/account/phone/bizflex-transfer';
export const GET_ACCOUNT_ENQUIRY = (number = '') =>
  `/account/account-enquiry/${number}`;
export const TRANSFER_BTW = '/account/transfer-between-accounts';

export const BULK_TRANSFER = '/school/bulk-transfer-to-other-banks';
export const ADD_BENEFICIARY = '/beneficiary/create';
export const GET_BENEFICIARY = '/beneficiary?limit=50&page=1';
export const GET_DATA_PLAN = (number = '') => `/bill/get-data-plan/${number}`;
export const GET_BILL_CATEGORY = '/bill/get-bill-categories';
export const GET_CATEGORY_BILLER = (id = '') =>
  `/bill/get-category-biller/${id}`;
export const GET_BILLER_PRODUCTS = (id = '') =>
  `/bill/get-biller-products/${id}`;
export const CREATE_SAVING_PLAN = '/savings/create-saving-plan';
export const CREATE_FIXED_SAVING = '/savings/create-fixed-saving';
export const GET_USER_SAVINGS = '/savings/get-user-savings/?type=&status';
export const WITHDRAW_SAVING = 'savings/withdraw-saving';
export const GET_SAVING_TRANSACTIONS = (id = '') =>
  `saving-transaction/user-saving-transactions/?savingId=${id}&page=1`;
export const FREEZE_CARD = 'card/suspend-card';
export const UN_FREEZE_CARD = 'card/unsuspend-card';
export const CARD_DETAILS = 'card/get-card-details';
export const REQUEST_PHYSICAL_CARD = 'card/request-physical-card';
export const CREATE_CLIENT = 'clients';
export const GET_CLIENTS = 'clients';
export const GET_CLIENT = (id = '') => `clients/${id}`;
export const UPDATE_CLIENT = (id = '') => `clients/${id}`;
export const CREATE_PRODUCT = 'products';
export const GET_PRODUCT = (id = '') => `products/${id}`;
export const GET_PRODUCTS = 'products';
export const UPDATE_PRODUCT = (id = '') => `products/${id}`;
export const CREATE_VIRTUAL_ACCOUNT = 'business/create-virtual-account';
export const ADD_NO_OF_SIGNATORIES = '/business/add-no-of-signatories';
export const ADD_NO_OF_DIRECTORS = '/business/add-no-of-directors';
export const GET_INV_SUB = '/invoice/get-invoice-subscriptions';
export const CREATE_SUB = '/invoice/create-invoice-subscription';
export const GET_INVOICES = '/invoice/get-all-invoices';
export const FUND_DOLLAR = '/card/fund-dollar-card';
export const FUND_NAIRA = '/card/fund-naira-card';
export const PHYSICAL_CARD = 'card/request-physical-card';
export const CALCULATE_INVOICE_TOTAL = '/invoice/get-invoice-total-price';
export const UPDATE_INVOICE_SETTINGS = '/invoice/update-invoice-settings';
export const GET_INVOICE_SETTINGS = '/invoice/get-invoice-settings';
export const UPLOAD_INVOICE_LOGO = '/upload/upload-image';
export const DELETE_INVOICE = (id = '') => `invoice/delete-invoice/${id}`;
export const GET_INVOICE = '/invoice/get-invoice';
export const UPDATE_INVOICE = (id = '') => `invoice/update-invoice/${id}`;
export const GET_TOTAL_SAVINGS = '/savings/total-savings';
export const SEND_INVOICE = '/invoice/send-invoice';
export const CREATE_RECURRING_INVOICE = '/invoice/recurring-invoice';
export const UPDATE_INVOICE_STATUS = (id = '') =>
  `/invoice/update-invoice-payment-status/${id}`;

export const GET_INVOICE_AUDIT = '/invoice/audit';

export const UNREG_BUS_NAME = '/business/add-business-name-registration';
export const LLC_BUS_NAME = '/business/add-llc-registration';
export const NGO_BUS_NAME = '/business/add-ngo-registration';
export const UNREG_DIR_ONFO = '/business/add-businessname-director-details';
export const UNREG_PROFILE_VERI =
  '/business/business-name-profile-verification';
export const CONFIRM_TRANSFER = '/business/confirm-registration-fee-transfer';
export const VERIFY_TRANSFER =
  '/business/confirm-card-registration-fee-payment';
export const GET_TRANSFER = '/business/pay-registration-fee-with-transfer';

export const ADD_SELF_DIR = '/business/add-llc-director-details';
export const ADD_OTHER_DIR = '/business/add-llc-other-directors';
export const ADD_NGO_SELF_DIR = '/business/add-ngo-trustee-details';
export const ADD_NGO_OTHER_DIR = '/business/add-ngo-other-trustee-details';
export const ADD_WIT_SEC = '/business/add-secretary-or-witness';
export const MAKE_WIT_SEC = '/business/make-director-secretary-or-witness';
export const COST = '/business/get-business-registration-cost';
export const GET_UNREG_DIR = '/business/get-unregistered-directors';
export const COMPLIANCE = '/business/vas-service/name-availability-check';
export const SHARES = '/business/unregistered-share-allocation';
export const SKIP = '/business/skip-signatory';

export const GET_FLOW = '/account/inflow-transaction-graph-data?duration';
export const INTERNAL_ACCOUNT_ENQUIRY = '/account/internal-account-enquiry';
export const CARD_CREATION_QUOTE = '/card/card-creation/quote';
export const GET_USER_ACCOUNT = '/account/user';

export const INTERNAL_PHONE_ENQUIRY = '/account/internal-account-enquiry/phone';
export const INTERNAL_EMAIL_ENQUIRY = '/account/internal-account-enquiry/email';

export const GET_DOLLAR_CARD_TRANSACTIONS =
  '/card/get-dollar-card-transactions';
export const GET_NAIRA_CARD_TRANSACTIONS = '/card/get-naira-card-transactions';

export const GET_CARD_TRANSACTION = (id = '', transactionRef = '') =>
  `/card/get-card-transaction?id=${id}&transactionRef=${transactionRef}`;

export const GET_INVOICE_PREVIEW = 'invoice/preview-invoice';
export const DELETE_ACCOUNT = 'auth/delete-account';
export const GET_INCOME_BANDS = '/card/income-bands';
export const GET_EMPLOYER_STATUS = '/card/employment-status/list';
export const GET_DESIGNATIONS = '/card/designations';
export const GET_INCOME_SOURCE = '/card/income-sources';
export const GET_OCCUPATIONS = '/card/occupations';
export const UPGRADE_CARD_HOLDER = '/card/upgrade-card-holder';
export const CREATE_PERSONAL_GLOBAL_ACCOUNT = '/card/create-account';
export const GET_USD_ACCOUNT_DETAILS = 'card/global/usd/details';
export const GET_EUR_ACCOUNT_DETAILS = 'card/global/euro/details';

export const GET_DYNAMIC_PAYMENT_LIST = '/payment/dynamic/list';
export const GET_LINK_DETAILS = (uuid = '') => `/payment/link/${uuid}`;
export const CREATE_PAYMENT_LINK_SETTINGS = '/payment/link/general/create';
export const UPDATE_PAYMENT_LINK_SETTINGS = '/payment/settings/update';
export const GET_PAYMENT_LINK_SETTINGS = '/payment/settings';
export const CREATE_PAYMENT_LINK = '/payment/link/create';
export const GET_PAYMENT_LINK_TOTAL = (id = '', status = '', reference) =>
  `business/payment-link/${id}/total?status=${status}&reference=${reference}`;
export const GET_PAYMENT_LINK_COUNT = (id = '') => `payment/link/${id}/count`;
export const VIEW_CUSTOMER_PAYMENT_LINK = 'payment/link/d/customer';
export const VIEW_CUSTOMER_GENERAL_PAYMENT_LINK = 'payment/link/g/customer';
export const UPDATE_PAYMENT_LINK_STATUS = '/payment/link/update';
export const INITIATE_PAYMENT_ORDER =
  '/business/dynamic/initiate-payment-order';
export const INITIATE_GENERAL_PAYMENT_ORDER =
  '/business/general/initiate-payment-order';
export const GET_GENERAL_PAYMENT_LINK = 'payment/link/user';
export const CONFIRM_PAYMENT_LINK_ORDER = 'business/confirm-payment-link-order';
export const PAYMENT_SETTINGS = 'users/payment/settings';
export const GET_ACCOUNT_TIERS = 'account/tiers';
export const INITIATE_INVOICE_ORDER =
  '/business/invoice/initiate-payment-order';
export const GET_INVOICE_TOTAL_PRICE = '/invoice/get-invoice-total-price';
export const CONFIRM_INVOICE_ORDER = '/business/confirm-invoice-link-order';
export const ACCOUNT_TIER_UPGRADE = '/business/tier/upgrade';
export const GLOBAL_RATES = 'card/get-global-rates';
export const GLOBAL_TRANSACTIONS = 'card/global/transactions';
export const ACCOUNT_UPGRADE_QUOTE = 'card/account-upgrade/quote';
export const GET_GLOBAL_TRANSACTIONS = (page = '', id = '') =>
  `card/global/transactions?page=${page}&account_id=${id}`;
export const COVERT_QUOTE = 'card/global/convert-quote';
export const CONVERT_FX = 'card/global/convert';
export const SEND_NIN_OTP = 'verification/send-verify-nin-otp';
export const VERIFY_NIN_OTP = 'verification/verify-nin-with-otp';
export const RESEND_NIN_OTP = 'verification/resend-verify-nin-otp';
export const GET_WALLET_BALANCE = (id) => `account/wallet?currency=${id}`;
