export const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    borderRadius: '8px',
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
};

export const formatDate = (date, fallback = '') => {
  if (!date) return fallback;

  return new Date(date).toLocaleDateString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const trim = (str) => {
  return str?.length > 20 ? str.substring(0, 20) + '...' : str;
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return date.toLocaleString('en-US', options);
};

export const formatDateTime = (date, fallback = '') => {
  if (!date) return fallback;

  return new Date(date).toLocaleTimeString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const formatDates = (date, fallback = '') => {
  if (!date) return fallback;

  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};


export const trimID = (str) => {
  return str?.length > 30 ? str.substring(0, 30) + '...' : str;
};

export const useLogOut = () => {
  return () => {
    sessionStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };
};
