import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Flex,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Select,
  Checkbox,
  Stack,
  Textarea,
  HStack,
  Button,
} from '@chakra-ui/react';
import { MdOutlineClose } from 'react-icons/md';
import {
  useCreatePaymentLink,
  useGetPaymentSettings,
} from '../../services/query/paymentLink';
import useCustomToast from '../../utils/notifications';
import { useNavigate } from 'react-router-dom';

const Layout = ({
  label,
  value,
  type,
  name,
  onChange,
  dis,
  isDisabled,
  holder,
  maxLength,
  pattern,
  inputMode
}) => {
  return (
    <Box>
      <Text color="#ffff" fontSize="14px" fontWeight={500}>
        {label}
      </Text>
      <InputGroup w="full">
        {name === 'phone' && (
          <InputLeftAddon
            border="1px solid #fff"
            color={value ? '#000' : ''}
            bg={value ? '#E8EFFF' : 'transparent'}
            h="60px"
            pl="14px"
          >
            +234
          </InputLeftAddon>
        )}
        <Input
          name={name}
          type={type}
          h="60px"
          border="1px solid #ffff"
          borderRadius="8px"
          bg={value ? 'transparent' : 'transparent'} // Background for input
          color="#ffffff" // Set text color to contrast with the background
          value={value}
          _placeholder={{ fontSize: '14px', color: '#ffffff99' }} // Placeholder color for visibility
          isDisabled={isDisabled}
          placeholder={holder}
          isReadOnly={dis}
          onChange={onChange}
          maxLength={maxLength}
          pattern={pattern}
          inputMode={inputMode}
          p="14px"
          caretColor="white" // Force caret (cursor) color
        />
      </InputGroup>
    </Box>
  );
};

const GenerateLinkDrawer = ({
  isOpen,
  onClose,
  setOpen,
  setPaymentLink,
  paymentLinkFee,
  refetchFunction,
  setTab,
  act,
  dis,
  settings,
}) => {
  const [values, setValues] = useState({
    name: '',
    amount: '',
    email: '',
    phone: '',
    description: '',
  });

  const { successToast, errorToast } = useCustomToast();
  const [linkSubmitType, setLinkSubmitType] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setValues((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const [chargeFee, setChargeFee] = useState(false);
  const [postPaymentInstructions, setPostPaymentInstructions] = useState(false);

  const { mutate, isLoading } = useCreatePaymentLink({
    onSuccess: (res) => {
      setPaymentLink(res?.data?.url);
      onClose();
      setValues({
        name: '',
        amount: '',
        email: '',
        phone: '',
        description: '',
      });
      setChargeFee(false);
      setPostPaymentInstructions(false);
      if (res?.data?.setupStatus !== 'DRAFT') {
        successToast(res?.message);
        setOpen(true);
      } else {
        successToast('Payment link have been saved to draft');
        act && setTab('Link Saved to Draft');
        if (dis) {
          navigate('/payment-link/view-payment-link');
        }
      }
      refetchFunction && refetchFunction();
    },
    onError: (res) => {
      errorToast(
        res?.response?.data?.message || res?.message || 'An Error Occured'
      );
    },
  });

  const getNumber = (str) => {
    const arr = str.split('');
    const out = [];
    for (let i = 0; i < arr.length; i++) {
      if (!isNaN(arr[i])) {
        out.push(arr[i]);
      }
    }
    return Number(out.join(''));
  };

  const chargeCustomerFee =
    Number(values?.amount?.replace(/\D/g, '')) +
    Number(paymentLinkFee?.data?.paymentLinkFee);
  const userFee = () => {
    if (values?.amount) {
      const amount = Number(values?.amount.replace(/\D/g, ''));

      if (!chargeFee) {
        return amount - Number(paymentLinkFee?.data?.paymentLinkFee || 0);
      } else {
        return amount;
      }
    }
    return 0;
  };
  const updateTextView = (event) => {
    const num = getNumber(event.target.value);
    if (num === 0) {
      setValues({
        ...values,
        amount: '',
      });
    } else {
      setValues({
        ...values,
        amount: num.toLocaleString(),
      });
    }
  };

  const handleSubmit = () => {
    const number = values?.phone?.slice(1);
    mutate({
      name: values?.name,
      amount: Number(values?.amount?.replace(/\D/g, '')),
      description: values?.description,
      includePaymentDetails: postPaymentInstructions,
      phone: number ? `+234${number}` : null,
      email: values?.email ? values.email : null,
      chargeCustomer: chargeFee,
      status: 'ACTIVE',
      setupStatus: 'PUBLISHED',
      settingId: Number(settings?.data?.id),
    });
  };

  const handleSubmitDraft = () => {
    const number = values?.phone?.slice(1);
    mutate({
      name: values?.name,
      amount: Number(values?.amount?.replace(/\D/g, '')),
      description: values?.description,
      includePaymentDetails: postPaymentInstructions,
      phone: number ? `+234${number}` : null,
      email: values?.email ? values.email : null,
      chargeCustomer: chargeFee,
      settingId: Number(settings?.data?.id),
      setupStatus: 'DRAFT',
      Status: 'INACTIVE',
    });
  };

  return (
    <Drawer
      autoFocus={false}
      size="md"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      {' '}
      <DrawerOverlay />
      <DrawerContent py="20px" bgColor="#0F172B" color="#fff">
        <DrawerBody p={0}>
          <Flex
            justifyContent="flex-end"
            align="center"
            zIndex={2}
            pos="sticky"
            top="0"
            pb="15px"
            px="20px"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              w="30px"
              h="30px"
              color="#333"
              bg="#F2F2F21A"
              rounded="full"
              cursor="pointer"
              onClick={() => {
                onClose();
              }}
            >
              <Flex>
                <MdOutlineClose color="#ffff" fontSize="20px" />
              </Flex>
            </Flex>
          </Flex>
          <Box textAlign="center">
            <Text fontWeight={700} fontSize={{ base: '24px', md: '30px' }}>
              Generate Link
            </Text>
            <Text pt="8px" fontSize={{ base: '12px', md: '1rem' }}>
              Create a new payment link with the required <br /> information
              payments need to be made
            </Text>
            <Flex
              flexDir="column"
              px="40px"
              pt="29px"
              gap="1rem"
              textAlign="left"
              color="#ffff"
            >
              <Box>
                <Text
                  fontWeight={500}
                  pb="10px"
                  fontSize={{ base: '12px', md: '14px' }}
                >
                  Payment Name
                </Text>
                <Layout
                  opt
                  mb
                  value={values.name}
                  name="name"
                  holder="Enter Name"
                  maxLength={35}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </Box>
              <Box>
                <Text
                  fontWeight={500}
                  pb="10px"
                  fontSize={{ base: '12px', md: '14px' }}
                >
                  Amount
                </Text>
                <Layout
                  opt
                  mb
                  value={values.amount}
                  name="amount"
                  type="tel"
                  inputMode="decimal"
                  pattern="[0-9.,]+"
                  holder="Enter amount"
                  onChange={(e) => {
                    updateTextView(e);
                  }}
                />
                <Flex gap="10px">
                  <Text fontSize="10px" pt="10px">
                    You’ll receive:{' '}
                    <span style={{ color: '#F3C948' }}>
                      ₦{userFee()?.toLocaleString()}{' '}
                    </span>
                  </Text>
                  {values?.amount && chargeFee && (
                    <Text fontSize="10px" pt="10px">
                      Customer Payment{' '}
                      <span style={{ color: '#F3C948' }}>
                        ₦{chargeCustomerFee?.toLocaleString('en-US')}
                      </span>
                    </Text>
                  )}
                </Flex>
              </Box>
              <Box>
                <Text
                  fontWeight={500}
                  pb="10px"
                  fontSize={{ base: '12px', md: '14px' }}
                >
                  Email Address{' '}
                  <span style={{ fontSize: '12px', fontWeight: '400' }}>
                    (optional){' '}
                  </span>
                </Text>
                <Layout
                  opt
                  mb
                  value={values.email}
                  name="email"
                  holder="Enter Name"
                  type="email"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </Box>
              <Box>
                <Text
                  fontWeight={500}
                  pb="10px"
                  fontSize={{ base: '12px', md: '14px' }}
                >
                  Phone Number
                  <span style={{ fontSize: '12px', fontWeight: '400' }}>
                    (optional){' '}
                  </span>
                </Text>
                <Layout
                  name="phone"
                  opt
                  type="tel"
                  pattern="[0-9]{11}"
                  value={values.phone}
                  onChange={(e) => {
                    const inputPhone = e.target.value
                      .replace(/\D/g, '')
                      .slice(0, 11);
                    setValues({
                      ...values,
                      phone: `${inputPhone}`,
                    });
                  }}
                  holder="Enter Phone Number"
                />
              </Box>
              <Box>
                <Text
                  fontWeight={500}
                  pb="10px"
                  fontSize={{ base: '12px', md: '14px' }}
                >
                  Payment Description
                </Text>
                <Textarea
                  name="description"
                  bg={values?.description ? 'transparent' : 'transparent'}
                  value={values.description}
                  border="1px solid #ffff"
                  placeholder="Enter payment description"
                  _placeholder={{ fontSize: '12px' }}
                  onChange={handleInputChange}
                  maxLength={255}
                  caretColor="white"
                  color="#ffffff"
                />
              </Box>
              <Box pt={2}>
                <Stack spacing={5}>
                  <HStack alignItems="start">
                    <Checkbox
                      isChecked={chargeFee}
                      onChange={() => setChargeFee(!chargeFee)}
                      size="lg"
                    ></Checkbox>
                    <Box>
                      <Text
                        fontWeight={600}
                        pb="10px"
                        fontSize={{ base: '12px', md: '14px' }}
                      >
                        Charge Fee to your Customer
                      </Text>

                      <Text fontSize={{ base: '8px', md: '11px' }}>
                        Enable this option to pass the fee directly to your
                        customer. The customer must pay the exact total
                        including the fee, to avoid transaction failure.
                      </Text>
                    </Box>
                  </HStack>

                  <HStack alignItems="start">
                    <Checkbox
                      isChecked={postPaymentInstructions}
                      onChange={() =>
                        setPostPaymentInstructions(!postPaymentInstructions)
                      }
                      size="lg"
                    ></Checkbox>
                    <Box>
                      <Text
                        fontWeight={600}
                        pb="10px"
                        fontSize={{ base: '12px', md: '14px' }}
                      >
                        Include post payment instruction
                      </Text>

                      <Text fontSize={{ base: '8px', md: '11px' }}>
                        Provide detailed instructions or messages for your
                        customers to follow after their payment. This could
                        include information on next steps, contact details, or
                        any important actions they need to take.
                      </Text>
                    </Box>
                  </HStack>
                </Stack>
              </Box>
              <Flex gap="15px" alignItems="center" mx="20px">
                <Button
                  h="56px"
                  w="full"
                  border="2px solid #FFFFFF"
                  bgColor="transparent"
                  _hover={{
                    bgColor: '#F3C948',
                    color: '#000',
                    border: 'unset',
                  }}
                  mt="3rem"
                  mb="3rem"
                  isLoading={linkSubmitType === 'DRAFT' && isLoading}
                  isDisabled={
                    !values?.name || !values?.amount || !values.description
                  }
                  onClick={() => {
                    setLinkSubmitType('DRAFT');
                    handleSubmitDraft();
                  }}
                >
                  Save to Draft
                </Button>
                <Button
                  h="56px"
                  mt="3rem"
                  w="full"
                  mb="3rem"
                  isLoading={linkSubmitType === 'PUBLISH' && isLoading}
                  isDisabled={
                    !values?.name || !values?.amount || !values.description
                  }
                  onClick={() => {
                    setLinkSubmitType('PUBLISH');
                    handleSubmit();
                  }}
                >
                  Publish Link
                </Button>
              </Flex>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default GenerateLinkDrawer;
