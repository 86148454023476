import React, { useEffect, useState } from "react";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { BsCircle, BsFillCircleFill } from "react-icons/bs";
import { slides } from "../../common/constants";
import { useLocation } from "react-router-dom";

const SideBarr = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((slideIndex) => (slideIndex + 1) % slides.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  const { pathname } = useLocation();

  const renderCircle = (index) => {
    const isActive = index === slideIndex;
    const color = isActive ? "#5B83DA" : "#2463EB";
    const opacity = isActive ? 1 : 0.5;
    return (
      <Box key={index}>
        {isActive ? (
          <BsFillCircleFill size="10px" color={color} opacity={opacity} />
        ) : (
          <BsCircle size="10px" color={color} opacity={opacity} />
        )}
      </Box>
    );
  };

  return (
    <Flex w="100%" flexDirection="column">
      <Flex flexDir="column" align="flex-end" minH="100vh">
        <Flex
          flexDir="column"
          pos="fixed"
          top="10"
          align="center"
          justifyContent="center"
          bg="#0F172B"
          minH="88vh"
          w="36rem"
          borderRadius="16px"
        >
          <Box
            w="450px"
            pos="relative"
            h={pathname === "/create-account/step-2" ? "" : "450px"}
            overflow="hidden"
          >
            <Flex
              flexDir="column"
              display={pathname === "/create-account/step-2" ? "flex" : "none"}
              h="100%"
              align="center"
            >
              <Image h="474px" w="403px" src="/assets/slide5.svg" />

              <Center flexDir="column" mt="-50px" textAlign="center">
                <Text color="#fff" mb="16px" fontSize="25px" fontWeight={500}>
                  Register Your Busines
                </Text>

                <Text fontSize="14px" color="#C6D8FF">
                  Please be informed that the Business registration you’re about
                  to initiate requires a Payment for successful processing
                </Text>
              </Center>
            </Flex>

            <Flex
              pos="absolute"
              display={pathname === "/create-account/step-2" ? "none" : "flex"}
              w={`${slides.length}00%`}
              h="100%"
              transition={`transform ${7000 / 1000}s ease-in-out`}
              transform={`translateX(-${slideIndex * (100 / slides.length)}%)`}
            >
              {slides.map((data, i) => (
                <Flex
                  flexDir="column"
                  key={i}
                  w={`${100 / slides.length}%`}
                  h="100%"
                  align="center"
                >
                  <Image h="300px" w="300px" src={data.img} />

                  <Center flexDir="column" textAlign="center">
                    <Text
                      color="#fff"
                      mb="16px"
                      fontSize="25px"
                      fontWeight={500}
                    >
                      {data?.title}
                    </Text>

                    <Text fontSize="14px" color="#C6D8FF">
                      {data?.text}
                    </Text>
                  </Center>
                </Flex>
              ))}
            </Flex>
          </Box>

          <Flex
            gap="15px"
            mt="30px"
            display={pathname === "/create-account/step-2" ? "none" : "flex"}
          >
            {[...Array(slides.length)].map((_, index) => (
              <Box key={index}>{renderCircle(index)}</Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SideBarr;
