import { useMutation, useQuery } from 'react-query';
import {
  createPaymentLink,
  getPaymentLinkTotal,
  getPaymentLinkSettings,
  getPaymentLinkCount,
  viewCustomerPaymentLink,
  createPaymentLinkSettings,
  updatePaymentLinkSettings,
  getGeneralPaymentLink,
  viewCustomerGeneralPaymentLink,
  initiatePaymentOrder,
  initiateGeneralPaymentOrder,
  confirmPaymentLinkOrder,
  getPaymentSettings,
  updatePaymentLinkStatus,
  getLinkDetails,
  getAllDynamicPayments,
  initiateInvoiceOrder,
} from '../api/paymentLink';

export const useCreatePaymentLinkSettings = (options = {}) => {
  const { mutate, isLoading } = useMutation(createPaymentLinkSettings, {
    mutationKey: 'updatePaymentLinkSettings',
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdatePaymentLinkSettings = (options = {}) => {
  const { mutate, isLoading } = useMutation(updatePaymentLinkSettings, {
    mutationKey: 'updatePaymentLinkSettings',
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetPaymentLinkSettings = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getPaymentLinkSettings',
    getPaymentLinkSettings,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useCreatePaymentLink = (options = {}) => {
  const { mutate, isLoading } = useMutation(createPaymentLink, {
    mutationKey: 'createPaymentLink',
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetPaymentLinkTotal = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(getPaymentLinkTotal, {
    mutationKey: 'getPaymentLinkCount',
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useGetPaymentLinkCount = (options = {}) => {
  const { mutate, isLoading, data, refetch } = useMutation(
    getPaymentLinkCount,
    {
      mutationKey: 'getPaymentLinkCount',
      ...options,
    }
  );

  return { mutate, isLoading, data, refetch };
};

export const useViewCustomerPaymentLink = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(viewCustomerPaymentLink, {
    mutationKey: 'viewCustomerPaymentLink',
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useGetAllDynamicPayments = (
  accountId = '',
  page = '',
  limit = '',
  startDate = '',
  endDate = '',
  // status = "",
  options = {}
) => {
  const { data, isLoading, refetch } = useQuery(
    [
      'getAllDynamicPayments',
      accountId,
      page,
      limit,
      startDate,
      endDate,
      // status,
    ],
    getAllDynamicPayments,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetLinkDetails = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(getLinkDetails, {
    mutationKey: 'getLinkDetails',
    ...options,
  });

  return { mutate, isLoading, data };
};
export const useUpdatePaymentLinkStatus = (options = {}) => {
  const { mutate, isLoading } = useMutation(updatePaymentLinkStatus, {
    mutationKey: 'updatePaymentLinkStatus',
    ...options,
  });

  return { mutate, isLoading };
};

export const useViewCustomerGeneralPaymentLink = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(
    viewCustomerGeneralPaymentLink,
    {
      mutationKey: 'viewCustomerGeneralPaymentLink',
      ...options,
    }
  );

  return { mutate, isLoading, data };
};

export const useInitiatePaymentOrder = (options = {}) => {
  const { mutate, isLoading } = useMutation(initiatePaymentOrder, {
    mutationKey: 'initiatePaymentOrder',
    ...options,
  });

  return { mutate, isLoading };
};

export const useInitiateGeneralPaymentOrder = (options = {}) => {
  const { mutate, isLoading } = useMutation(initiateGeneralPaymentOrder, {
    mutationKey: 'initiatePaymentOrder',
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetGeneralPaymentLink = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getGeneralPaymentLink, {
    mutationKey: 'getGeneralLink',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useConfirmPaymentLinkOrder = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(confirmPaymentLinkOrder, {
    mutationKey: 'confirmPaymentLinkOrder',
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useGetPaymentSettings = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getPaymentSettings',
    getPaymentSettings,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
