import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Spinner,
  Skeleton,
  Text,
  Collapse,
  VStack,
} from '@chakra-ui/react';
import { siderBarData } from '../../common/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogoutIcon, WarnIcon } from '../../common/Images';
import { useGetFlags } from '../../../services/query/account';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useLogout } from '../../../services/query/auth';

const SideDrawer = ({ isOpen, onClose }) => {
  const { mutate: logout, isLoading } = useLogout({
    onSuccess: (res) => {
      sessionStorage.clear();
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    },
    onError: (err) => {},
  });

  const { isLoading: isUser } = useGetFlags();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const action = () => {
    logout();
  };

  const [openSubItems, setOpenSubItems] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState('');

  const handleToggleSubItem = (name) => {
    setOpenSubItems((prevState) => {
      const newOpenSubItems = {};

      Object.keys(prevState).forEach((item) => {
        newOpenSubItems[item] = false;
      });

      const activeParentItem = siderBarData.find((item) =>
        pathname.includes(item.path)
      )?.name;

      newOpenSubItems[activeParentItem] = true;

      if (name) newOpenSubItems[name] = !prevState[name];

      return newOpenSubItems;
    });
  };

  useEffect(() => {
    handleToggleSubItem(null);
  }, [pathname]);

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bgColor="red" color="#000">
        <DrawerBody
          p={0}
          overflowY="auto" // Changed this to make it scrollable
          className="no_scroller scrollable"
          scrollBehavior="smooth"
          transition="0.5s ease-in-out"
        >
          <Flex
            flexDir="column"
            justifyContent="space-between"
            // position={'fixed'}
            zIndex="5"
            w="full"
            borderRight="0.5px solid rgba(104, 132, 202, 0.5)"
            bg="#0F172B"
            color="#fff"
          >
            <Box h="100vh" overflowY="auto">
              <Flex
                bg="#0F172B"
                pos="sticky"
                px="24px"
                zIndex={55}
                pt="30px"
                top="0"
                pb="30px"
                borderBottom="1px solid #314169"
                justifyContent="space-between"
                align="center"
              >
                <Image
                  src="/assets/dark-logo.png"
                  w="160px"
                  h="55px"
                  objectFit="cover"
                />
              </Flex>

              <Box mx="20px" mt="20px">
                <Flex
                  flexDir="column"
                  display={isUser ? 'flex' : 'none'}
                  gap="20px"
                >
                  <Skeleton h="50px" borderRadius="12px" w="full"></Skeleton>
                  <Skeleton h="50px" borderRadius="12px" w="full"></Skeleton>
                  <Skeleton h="50px" borderRadius="12px" w="full"></Skeleton>
                  <Skeleton h="50px" borderRadius="12px" w="full"></Skeleton>
                  <Skeleton h="50px" borderRadius="12px" w="full"></Skeleton>
                  <Skeleton h="50px" borderRadius="12px" w="full"></Skeleton>
                </Flex>

                <Box display={isUser ? 'none' : 'block'}>
                  {siderBarData?.map((item, i) => {
                    return (
                      <VStack
                        key={i}
                        align="stretch"
                        mb="20px"
                        className={
                          !pathname.includes(item?.path) && 'parent_nav'
                        }
                        gap={0}
                      >
                        <Flex
                          align="center"
                          p={2}
                          pt={3}
                          cursor="pointer"
                          onClick={() =>
                            item.sub
                              ? (showMenu && currentIndex === item.id
                                  ? setShowMenu(false)
                                  : !showMenu && setShowMenu(true),
                                setCurrentIndex(item.id))
                              : (navigate(item.path),
                                setShowMenu(false),
                                setCurrentIndex(''),
                                onClose())
                          }
                          bg="transparent"
                          color={
                            showMenu
                              ? showMenu && currentIndex === item.id
                                ? '#F3C948'
                                : '#B4B4B4'
                              : pathname.includes(item.path)
                              ? '#F3C948'
                              : '#B4B4B4'
                          }
                          _hover={{
                            bg: '#182444',
                            color: '#F3C948',
                          }}
                          h="45px"
                          borderRadius="10px"
                          transition=".3s ease-in-out"
                          position="relative"
                          gap="24px"
                        >
                          <Box className="hovered_image">{item.hover}</Box>

                          <Box className="initial_image">
                            {showMenu
                              ? showMenu && currentIndex === item.id
                                ? item.hover
                                : item.icon
                              : pathname.includes(item.path)
                              ? item.sec
                              : item.icon}
                          </Box>
                          <Box>
                            <Text fontSize="12px">{item.name}</Text>
                          </Box>

                          {item.sub && (
                            <Flex
                              justifyContent="flex-end"
                              w="full"
                              color="#B4B4B4"
                            >
                              {showMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                            </Flex>
                          )}
                        </Flex>

                        {item?.sub && showMenu && (
                          <Collapse in={showMenu && currentIndex === item.id}>
                            <Box mt="5px">
                              {item.sub.map((subItem) => (
                                <Link
                                  key={subItem.name}
                                  to={subItem.path}
                                  style={{
                                    textDecoration: 'none',
                                    color: pathname.includes(subItem.path)
                                      ? '#B4B4B4'
                                      : '#B4B4B4',
                                  }}
                                >
                                  <Flex
                                    pl="40px"
                                    py="13px"
                                    onClick={onClose}
                                    fontSize="13px"
                                    fontWeight={500}
                                    bg={
                                      pathname.includes(subItem.path)
                                        ? '#182444'
                                        : ''
                                    }
                                    mb="10px"
                                    borderRadius="10px"
                                  >
                                    {subItem.name}
                                  </Flex>
                                </Link>
                              ))}
                            </Box>
                          </Collapse>
                        )}
                      </VStack>
                    );
                  })}
                </Box>

                <Box
                  mt="40px"
                  borderTop="1px solid #314169"
                  p={2}
                  fontSize="14px"
                  align="center"
                  py="30px"
                  fontWeight={400}
                  cursor="pointer"
                  onClick={action}
                >
                  {isLoading ? (
                    <Flex gap="8px" color="red" align="center">
                      <Spinner size="sm" /> Logging Out
                    </Flex>
                  ) : (
                    <Flex gap="8px" align="center" color="#b4b4b4">
                      <LogoutIcon fill="#B4B4B4" />
                      Log Out
                    </Flex>
                  )}
                </Box>
              </Box>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideDrawer;
