import { useMutation, useQuery } from 'react-query';
import * as queryKey from '../queryKeys';
import {
  calculateInvoiceTotal,
  confirmInvoiceOrder,
  createClient,
  createInvoice,
  createProduct,
  createRecurringInvoice,
  createSub,
  deleteInvoice,
  getAuditInvoice,
  getClient,
  getClients,
  getInvoice,
  getInvoiceSettings,
  getInvoiceSub,
  getInvoiceTotalPrice,
  getInvoices,
  getProduct,
  getProducts,
  initiateInvoiceOrder,
  sendInvoice,
  updateClient,
  updateInvoice,
  updateInvoiceSettings,
  updateInvoiceStatus,
  updateProduct,
  uploadInvoiceLogo,
} from '../api/invoice';

export const useCreateClient = (options = {}) => {
  const { mutate, isLoading } = useMutation(createClient, {
    mutationKey: queryKey.CREATE_CLIENT,
    ...options,
  });
  return { mutate, isLoading };
};

export const useCreateInvoice = (options = {}) => {
  const { mutate, isLoading } = useMutation(createInvoice, {
    mutationKey: 'createInvoice',
    ...options,
  });
  return { mutate, isLoading };
};

export const useCreateSub = (options = {}) => {
  const { mutate, isLoading } = useMutation(createSub, {
    mutationKey: 'createSub',
    ...options,
  });
  return { mutate, isLoading };
};

export const useGetClients = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_CLIENTS,
    getClients,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetInvoices = (
  status = '',
  page = '',
  limit = '',
  options = {}
) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_INVOICES, status, page, limit],
    getInvoices,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetInvoiceSub = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getInvoiceSub',
    getInvoiceSub,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetClient = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getClient, {
    mutationKey: queryKey.GET_CLIENT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useCreateProduct = (options = {}) => {
  const { mutate, isLoading } = useMutation(createProduct, {
    mutationKey: queryKey.CREATE_PRODUCT,
    ...options,
  });
  return { mutate, isLoading };
};

export const useGetProducts = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_PRODUCTS,
    getProducts,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useUpdateClient = (options = {}) => {
  const { mutate, isLoading } = useMutation(
    (payload) => updateClient(payload.id, payload.data),
    {
      mutationKey: queryKey.UPDATE_CLIENT,
      ...options,
    }
  );

  return { mutate, isLoading };
};

export const useUpdateProduct = (options = {}) => {
  const { mutate, isLoading } = useMutation(
    (payload) => updateProduct(payload.id, payload.data),
    {
      mutationKey: queryKey.UPDATE_PRODUCT,
      ...options,
    }
  );

  return { mutate, isLoading };
};

export const useGetProduct = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getProduct, {
    mutationKey: queryKey.GET_PRODUCT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useCalculateInvoiceTotal = (options = {}) => {
  const { mutate, isLoading } = useMutation(calculateInvoiceTotal, {
    mutationKey: 'calculateInvoiceTotal',
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateInvoiceSettings = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateInvoiceSettings, {
    mutationKey: queryKey.UPDATE_INVOICE_SETTINGS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetInvoiceSettings = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getInvoiceSettings',
    getInvoiceSettings,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useUploadInvoiceLogo = (options = {}) => {
  const { mutate, isLoading } = useMutation(uploadInvoiceLogo, {
    mutationKey: 'UPLOAD_INVOICE_LOGO',
    ...options,
  });
  return { mutate, isLoading };
};

export const useDeleteInvoice = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteInvoice, {
    mutationKey: queryKey.DELETE_INVOICE,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetInvoice = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_INVOICE, id],
    getInvoice,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useUpdateInvoice = (options = {}) => {
  const { mutate, isLoading } = useMutation(
    (payload) => updateInvoice(payload.id, payload.data),
    {
      mutationKey: 'UPDATE_INVOICE',
      ...options,
    }
  );

  return { mutate, isLoading };
};

export const useSendInvoice = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendInvoice, {
    mutationKey: 'SEND_INVOICE',
    ...options,
  });
  return { mutate, isLoading };
};

export const useCreateRecurringInvoice = (options = {}) => {
  const { mutate, isLoading } = useMutation(createRecurringInvoice, {
    mutationKey: 'CREATE_RECURRING_INVOICE',
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateInvoiceStatus = (options = {}) => {
  const { mutate, isLoading } = useMutation(
    (payload) => updateInvoiceStatus(payload.id, payload.data),
    {
      mutationKey: 'UPDATE_INVOICE_Status',
      ...options,
    }
  );

  return { mutate, isLoading };
};

export const useGetInvoiceAudit = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_INVOICE_AUDIT, id],
    getAuditInvoice,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useInitiateInvoiceOrder = (options = {}) => {
  const { mutate, isLoading } = useMutation(initiateInvoiceOrder, {
    mutationKey: 'initiateInvoicePaymentOrder',
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetInvoiceTotalPrice = (options = {}) => {
  const { mutate, isLoading } = useMutation(getInvoiceTotalPrice, {
    mutationKey: 'getInvoiceTotalPrice',
    ...options,
  });

  return { mutate, isLoading };
};

export const useConfirmInvoiceLinkOrder = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(confirmInvoiceOrder, {
    mutationKey: 'confirmInvoiceOrder',
    ...options,
  });

  return { mutate, isLoading, data };
};
