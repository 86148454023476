import React from 'react';
import {
  Flex,
  Drawer,
  DrawerBody,
  Box,
  DrawerContent,
  DrawerOverlay,
  Text,
  DrawerHeader,
  CloseButton,
  Button,
} from '@chakra-ui/react';
import { formatDate, formatTime } from '../../utils/helper';

const SingleLinkTransDetail = ({ isOpen, onClose, details, setOpenReport }) => {
  const formatDateTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-US', dateOptions);

    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = dateObj.toLocaleTimeString('en-US', timeOptions);

    return {
      date: formattedDate,
      time: formattedTime,
    };
  };
  return (
    <Drawer
      isCentered
      trapFocus={false}
      size="sm"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      {' '}
      <DrawerOverlay
        backdropFilter="auto"
        backdropBlur="2px"
        paddingLeft="20px"
      />
      <DrawerContent
        overflowY="auto"
        color="#000"
        pt="16px"
        pb="24px"
        bg="#fff"
      >
        <DrawerHeader
          borderBottom="1px solid #E7E8EA"
          px="24px"
          pt="20px"
          pb="16px"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize={{ base: '18px', md: '20px' }}
              color="#2463EB"
              fontWeight={500}
            >
              {details?.type === 'DEBIT' ? 'Outflow' : 'Inflow'} Details
            </Text>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="20px"
              h="20px"
              color="#333"
              rounded="full"
              border="2px solid #B7B9BF"
              onClick={onClose}
            >
              <CloseButton color="#333333" fontSize="8px" />
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody p="0">
          <Flex
            alignItems="center"
            flexDir="column"
            py="24px"
            borderBottom="1px solid #E0E0E0"
          >
            {' '}
            <Text
              color="#0F172B"
              fontWeight={700}
              fontSize={{ base: '16px', md: '20px' }}
            >
              {/* {details?.type === 'DEBIT' ? '-' : ''}₦{' '}
          {Number(details?.amount)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) || '0.00'} */}
              ₦{' '}
              {Number(details?.amount)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </Text>
          </Flex>

          <Flex
            flexDir="column"
            gap="16px"
            color="#1E1E1E"
            fontSize="13px"
            py="16px"
            px="24px"
            borderBottom="1px solid #E0E0E0"
          >
            <Flex justifyContent="space-between" w="full">
              <Text>From</Text>
              <Box textAlign="right">
                <Text fontSize="15px" fontWeight={500}>
                  {details?.data?.accountName ||
                    details?.senderAccount?.accountName ||
                    'N/A'}
                </Text>
                <Box color=" #444">
                  <Text>
                    {details?.data?.bankNumber ||
                      details?.senderAccount?.accountNumber}
                  </Text>
                  <Text>
                    {details?.data?.bankName ||
                      details?.senderAccount?.bankName}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Flex>
          <Box pt="16px" px="24px">
            <Text color="#1E1E1E" fontSize="18px" fontWeight={500} pb="16px">
              Details
            </Text>
            <Flex flexDir="column" gap="24px">
              <Flex justifyContent="space-between" w="full" color=" #444">
                <Text fontSize="14px">Type</Text>
                <Box textAlign="right">
                  <Text
                    fontSize="14px"
                    color={details?.type === 'DEBIT' ? '#EB5757' : '#00A825'}
                  >
                    {details?.type?.toLowerCase() || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" w="full">
                <Text fontSize="14px">Payment Link</Text>
                <Box textAlign="right">
                  <Text fontSize="12px" fontWeight={500}>
                    {details?.data?.link || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" w="full" color=" #444">
                <Text fontSize="14px">Customer Email</Text>
                <Box textAlign="right">
                  <Text fontSize="14px" fontWeight={500}>
                    {details?.data?.email || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" w="full" color=" #444">
                <Text fontSize="14px">Customer Phone Number</Text>
                <Box textAlign="right">
                  <Text fontSize="14px" fontWeight={500}>
                    {details?.data?.phone || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" w="full" color=" #444">
                <Text fontSize="14px">Transaction Reference</Text>
                <Box textAlign="right">
                  <Text fontSize="14px">
                    {' '}
                    {details?.transactionReference || 'N/A'}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" w="full" color=" #444">
                <Text fontSize="14px">Date</Text>
                <Box textAlign="right">
                  <Text fontSize="14px">
                    {formatDateTime(details?.createdAt)?.date} |{' '}
                    {formatDateTime(details?.createdAt)?.time}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" w="full" color=" #444">
                <Text fontSize="14px">Description</Text>
                <Box textAlign="right">
                  <Text>{details?.narration || 'N/A'}</Text>
                </Box>
              </Flex>
            </Flex>
            <Flex pt="50px" gap="16px">
              <Button
                flex="1"
                h="56px"
                border="1px solid #F3C948"
                p="8px"
                fontSize={{ base: '13px', md: '16px' }}
                borderRadius="8px"
                color="#F3C948"
                bg="transparent"
                fontWeight={500}
                onClick={() => {
                  setOpenReport(true);
                  onClose();
                }}
              >
                Report Transaction
              </Button>
              <Button
                flex="1"
                h="56px"
                borderRadius="8px"
                fontSize={{ base: '13px', md: '16px' }}
              >
                Share Receipt
              </Button>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default SingleLinkTransDetail;
