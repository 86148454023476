import { useMutation, useQuery } from 'react-query';
import {
  getCountries,
  login,
  signUp,
  verifyEmail,
  createPassword,
  updatePassword,
  changePasswordOtp,
  changePinOtp,
  updateTransactionPin,
  sendEmailToken,
  logout,
} from '../api/auth';
import * as queryKey from '../queryKeys';

export const useLogin = (options = {}) => {
  const { mutate, isLoading } = useMutation(login, {
    mutationKey: queryKey.LOGIN,
    ...options,
  });
  return { mutate, isLoading };
};

export const useSignUp = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(signUp, {
    mutationKey: queryKey.SIGN_UP,
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useGetCountries = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.COUNTRIES],
    getCountries,
    {
      ...options,
    }
  );
  return { data, isLoading, refetch };
};

export const useSendEmailToken = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(sendEmailToken, {
    mutationKey: 'sendEmailToken',
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useVerifyEmail = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyEmail, {
    mutationKey: queryKey.VERIFY_EMAIL,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreatePassword = (options = {}) => {
  const { mutate, isLoading } = useMutation(createPassword, {
    mutationKey: queryKey.CREATE_PASS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdatePassword = (options = {}) => {
  const { mutate, isLoading } = useMutation(updatePassword, {
    mutationKey: queryKey.UPDATE_PASSWORD,
    ...options,
  });

  return { mutate, isLoading };
};

export const useChangePasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(changePasswordOtp, {
    mutationKey: queryKey.CHANGE_PASSWORD_OTP,
    ...options,
  });
  return { mutate, isLoading };
};

export const useChangePinOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(changePinOtp, {
    mutationKey: queryKey.CHANGE_PIN_OTP,
    ...options,
  });
  return { mutate, isLoading };
};

export const useUpdateTransactionPin = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateTransactionPin, {
    mutationKey: queryKey.UPDATE_TRANSACTION_PIN,
    ...options,
  });

  return { mutate, isLoading };
};

export const useLogout = (options = {}) => {
  const { mutate, isLoading } = useMutation(logout, {
    mutationKey: 'useLogout',
    ...options,
  });
  return { mutate, isLoading };
};
