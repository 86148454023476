import React from "react";
import {
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Button,
  Box,
  Image,
  Center,
} from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import { trimID } from "../../../utils/helper";

export const Main = () => {
  const list = [];

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          bg="transparent"
          position={"relative"}
          p="0"
          _hover={{ outline: "none" }}
          boxShadow="none"
          _active={{ outline: "none" }}
          _focus={{ outline: "none" }}
        >
          <Box>
            <Flex
              border="1px solid rgba(104, 132, 202, 0.30)"
              rounded="full"
              align="center"
              justifyContent="center"
              w="40px"
              h="40px"
            >
              <Image w="20px" h="20px" src="/assets/notification.svg" />
            </Flex>
          </Box>
          {list && list?.length === 0
            ? ""
            : list && (
                <Flex
                  justifyContent="center"
                  align="center"
                  bg={"red"}
                  textColor={"white"}
                  fontWeight={"extrabold"}
                  h="20px"
                  w="20px"
                  rounded={"full"}
                  position={"absolute"}
                  top={"-1"}
                  fontSize={"12px"}
                  ml={"9"}
                >
                  {list?.length === 0 ? "" : list?.length}
                </Flex>
              )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        boxShadow="lg"
        color="darkBg"
        mt="5px"
        borderRadius="8px"
        h="30vh"
        bg="#fff"
      >
        <PopoverHeader>
          <Flex alignItems="center" justifyContent="space-between">
            <Text size="sm">Notification</Text>
          </Flex>
        </PopoverHeader>
        <PopoverBody overflowY={"scroll"} height={"250px"}>
          {list?.length > 0 ? (
            list?.map((item, i) => (
              <Flex
                key={i}
                py="7px"
                px="11px"
                cursor="pointer"
                gap="10px"
                alignItems="flex-start"
              >
                <Box color="red" cursor="pointer">
                  <BsTrash />
                </Box>
                <Box>
                  <Text
                    fontWeight={500}
                    fontSize="13px"
                    color={item.isRead === true ? "#000" : "blueBg"}
                  >
                    {item?.title}
                  </Text>
                  <Text mt="4px" fontSize="13px" color="#bdbdbd">
                    {trimID(item?.body)}
                  </Text>
                </Box>
              </Flex>
            ))
          ) : (
            <Center fontWeight={500}>No notifications at the moment</Center>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
