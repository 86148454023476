import React from "react";
import { Box, HStack, Skeleton } from "@chakra-ui/react";
import { test } from "../common/constants";

const TableLoader = () => {
  return (
    <Box>
      {test?.map((i) => (
        <HStack gap="5rem" key={i} my={4} mx="30px">
          <Skeleton height="30px" width="20%" />
          <Skeleton height="30px" width="20%" />
          <Skeleton height="30px" width="20%" />
          <Skeleton height="30px" width="20%" />
          <Skeleton height="30px" width="20%" />
        </HStack>
      ))}
    </Box>
  );
};
export default TableLoader;
