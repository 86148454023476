import React, { useState } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Box,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';
import { BiCopy } from 'react-icons/bi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useCustomToast from '../../utils/notifications';

const GenerateLinkSuccess = ({ isOpen, onClose, paymentLink }) => {
  const [isMobile] = useMediaQuery('(max-width: 991px)');
  const [isCopy, setIsCopy] = useState(false);

  const { infoToast } = useCustomToast();

  const handleCopy = () => {
    // infoToast('Copied');
    setIsCopy(true);
  };
  const index = paymentLink?.indexOf('?');
  const truncatedLink =
    index !== -1 ? paymentLink?.slice(0, index) + '...' : paymentLink;

  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay
        backdropFilter="auto"
        backdropBlur="2px"
        paddingLeft="20px"
      />
      <ModalContent
        pb="40px"
        overflowY="auto"
        borderRadius="20px"
        bg="#fff"
        color="#000"
        mx="1rem"
        width="full"
        padding="10px"
      >
        <ModalHeader>
          <Flex justifyContent="flex-end">
            <Flex
              justifyContent="center"
              align="center"
              onClick={onClose}
              cursor="pointer"
              rounded="full"
              border="1px solid #444"
              width="20px"
              height="20px"
            >
              <IoMdClose size="15px" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex
            justifyContent="center"
            alignItems="center"
            borderRadius="50%"
            w="full"
            rounded="full"
            overflow="hidden"
          >
            <Image
              src="/assets/success.gif"
              w="150px"
              h="150px"
              objectFit="contain"
            />
          </Flex>
          <Box textAlign="center" pt="17px">
            <Text
              color="#0F172B"
              fontSize={{ base: '12px', md: '18px' }}
              fontWeight={600}
            >
              Payment Link Generated Successfully
            </Text>
            <Text
              color="#444444"
              fontWeight={500}
              fontSize={{ base: '10px', md: '14px' }}
            >
              You can copy your link to receive payments
            </Text>

            <Flex pt="28px" gap="14px">
              <Flex
                gap="14px"
                py="12px"
                px={{ base: '10px', md: '16px' }}
                border="1px solid #11377040"
                borderRadius="4px"
                fontSize={{ base: '10px', md: '12px' }}
                color="#8E8E8E"
                flex={2}
              >
                <Text>
                  {' '}
                  {paymentLink?.includes('?id=')
                    ? paymentLink?.substring(0, paymentLink?.indexOf('?id=')) +
                      '...'
                    : paymentLink}
                </Text>
              </Flex>

              <CopyToClipboard text={paymentLink} onCopy={handleCopy}>
                <Flex
                  border="1px solid #D5DDE2"
                  px="10px"
                  py="4px"
                  justifyContent="center"
                  bgColor={isCopy ? '#00A825' : '#0F172B'}
                  borderRadius="8px"
                  alignItems="center"
                  fontWeight={{ base: '400', md: '500' }}
                  color="#ffff"
                  fontSize={{ base: '12px', md: '14px' }}
                  gap="10px"
                  cursor="pointer"
                  flex={1}
                >
                  <Text>{isCopy ? 'Copied!' : 'Copy'}</Text>

                  <BiCopy fontSize="20px" />
                </Flex>
              </CopyToClipboard>
            </Flex>
            <Button
              w="90%"
              h="56px"
              mt="3rem"
              mx="auto"
              mb="1rem"
              onClick={() => {
                onClose();
              }}
            >
              Got it
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default GenerateLinkSuccess;
