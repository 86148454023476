export const REGISTER_FREELANCE = " REGISTER_FREELANCE";
export const GET_USER = "GET_USER";
export const UTILITY_BILL = "UTILITY_BILL";
export const CREATE_PIN = " CREATE_PIN";

export const LOGIN = "LOGIN";
export const SIGN_UP = "SIGN_UP";
export const COUNTRIES = "COUNTRIES";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const SEND_PASS_OTP = "SEND_PASS_OTP";
export const VERIFY_PASS_OTP = "VERIFY_PASS_OTP";
export const CHANGE_PASS = " CHANGE_PASS ";
export const CREATE_PASS = "CREATE_PASS";
export const VERIFY_PROFILE = "VERIFY_PROFILE";
export const ADD_SIGNATORIES = "ADD_SIGNATORIES ";
export const REGISTER_BUSINESS = "REGISTER_BUSINESS";
export const ADD_BIZ_OWNER_INFO = "ADD_BIZ_OWNER_INFO";
export const ADD_OTHER_DIRECTORS = "ADD_OTHER_DIRECTORS";
export const CREATE_SUB_ACCOUNT = "CREATE_SUB_ACCOUNT";
export const CREATE_TRANSACTION_PIN = "CREATE_TRANSACTION_PIN";
export const SEND_BVN_OTP = "SEND_BVN_OTP ";
export const VERIFY_BVN_PIN = "VERIFY_BVN_PIN";
export const RESEND_BVN_OPT = "RESEND_BVN_OPT ";
export const BUY_AIRTIME = "BUY_AIRTIME";
export const BUY_DATA = "BUY_DATA";
export const GET_PHONE_NETWORK = "GET_PHONE_NETWORK ";
export const GET_DATA_PLAN = "GET_DATA_PLAN";
export const GET_BILL_CATEGORY = "GET_BILL_CATEGORY";
export const GET_CATEGORY_BILLER = "GET_CATEGORY_BILLER";
export const GET_BILLER_PRODUCTS = "GET_BILLER_PRODUCTS";
export const BUY_ELECTRICITY = "BUY_ELECTRICITY";
export const BUY_CABLE_TV = "BUY_CABLE_TV";
export const REQUEST_CARD = "REQUEST CARD";
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_USER = "UPDATE_USER";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const CHANGE_PASSWORD_OTP = "CHANGE_PASSWORD_OTP";
export const TIMEZONE_REGIONS = "TIMEZONE_REGIONS";
export const CHANGE_PIN_OTP = "CHANGE_PIN_OTP";
export const UPDATE_TRANSACTION_PIN = "UPDATE_TRANSACTION_PIN";
export const FREEZE_CARD = "FREEZE_CARD";
export const UN_FREEZE_CARD = "UN_FREEZE_CARD";
export const CREATE_SAVING_PLAN = "CREATE_SAVING_PLAN";
export const CREATE_FIXED_SAVING = "CREATE_FIXED_SAVING";
export const GET_USER_SAVINGS = "GET_USER_SAVINGS";
export const WITHDRAW_SAVING = "WITHDRAW_SAVING";
export const GET_SAVING_TRANSACTIONS = "GET_SAVING_TRANSACTIONS";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENT = "GET_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const CREATE_VIRTUAL_ACCOUNT = "CREATE_VIRTUAL_ACCOUNT";
export const ADD_NO_OF_SIGNATORIES = "ADD_NO_OF_SIGNATORIES";
export const ADD_NO_OF_DIRECTORS = "ADD_NO_OF_DIRECTORS";
export const UPDATE_INVOICE_SETTINGS = "UPDATE_INVOICE_SETTINGS ";
export const GET_INVOICES = "GET_INVOICES ";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const GET_INVOICE = "GET_INVOICE";
export const GET_TOTAL_SAVINGS = "GET_TOTAL_SAVINGS";
export const GET_INVOICE_AUDIT = " GET_INVOICE_AUDIT";
export const GET_PLAN_FLOW = "GET_PLAN_FLOW";
export const GET_ACCOUNT_ENQUIRY = "GET_ACCOUNT_ENQUIRY";
export const GET_DYNAMIC_PAYMENT_LIST = "GET_DYNAMIC_PAYMENT_LIST";
export const GET_LINK_DETAILS = "GET_LINK_DETAILS";
export const UPDATE_PAYMENT_LINK_STATUS = "UPDATE_PAYMENT_LINK_STATUS";
