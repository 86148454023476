import {
  AccountIcon,
  CardIcon,
  ExpenseIcon,
  InvoiceIcon,
  NotificationIcon,
  PaymentIcon,
  PosIcon,
  SavingsIcon,
  SettingsIcon,
  TransactionIcon,
  TransferIcon,
  PayBillIcon,
  BuyAirtime,
  GlobalIcon,
  LinkIcon,
} from './Images';

export const slides = [
  {
    title: 'Your Transactions; All in One Place',
    img: '/assets/slide2.svg',
    text: 'Simplify your financial operations with our all-in-one transaction hub. Manage a corporate account, pay bills, initiate single or bulk transfers, and handle your digital checking account with multi-signatory options—all in one app!',
  },
  {
    title: 'Effortless Invoicing. Faster Payments',
    img: '/assets/slide3.svg',
    text: 'Generate professional invoices seamlessly and send payment receipts automatically. The BizFlex invoicing system helps you bill clients accurately and receive payments faster.',
  },
  {
    title: 'Request Virtual USD Card',
    img: '/assets/slide4.svg',
    text: 'Enjoy the flexibility of global and local spending. Get virtual Dollar cards for international transactions and virtual or physical Naira cards for everyday expenses.',
  },
];

export const globalSlides = [
  {
    title: 'Experience Global Freedom',
    img: '/assets/earth.png',
    text: 'Easily accept payments via Wire Transfer, ACH, and SEPA from your employer and platforms like Fiverr, Payoneer, PayPal, Google, Amazon, and more—directly into your BizFlex global account from Nigeria.',
  },
  {
    title: 'Instant Access & Competitive Rates!',
    img: '/assets/interest.png',
    text: 'Access your funds instantly and enjoy competitive conversion rates to your Naira Account. Seamless management of your money, whenever you need it.',
  },
];

export const accType = [
  // {
  //   title: 'Registered Business',
  //   text: 'I have registered my company with cac',
  // },
  // {
  //   title: 'Don’t have a registered business?',
  //   text: 'My business is not yet registered, I would like to Register with CAC',
  // },
  {
    title: 'Personal/Freelancer',
    text: "I don't have a business",
  },
];

export const busAccType = [
  {
    title: 'Business Name',
    charge: '₦19,500',
    duration: '5 to 12 Working days',
    text: 'I have registered my company with cac',
  },
  {
    title: 'LLC',
    charge: '₦65,000',
    duration: '10 to 15 Working days',
    text: 'My business is not yet registered, I would like to Register with CAC',
  },
  {
    charge: '₦150,000',
    duration: 'Two (2) Months',
    title: 'NGO',
    text: "I don't have a business",
  },
];

export const businessSetup = [
  {
    name: 'Business Details',
    path: '/setup/business/business-details',
  },
  {
    name: "Director's Information",
    path: '/setup/business/director-info',
  },
  {
    name: 'Profile Verification',
    path: '/setup/business/profile-verification',
  },
  {
    name: 'Payment',
    path: '/setup/business/payment',
  },
  {
    name: 'Create PIN',
    path: '/setup/business/transaction-pin',
  },
];

export const llcSetup = [
  {
    name: 'Business Details',
    path: '/setup/LLC/business-details',
  },
  {
    name: "Director's Information",
    path: '/setup/LLC/director-info',
  },
  {
    name: 'Statement Of Issued Share Capital',
    path: '/setup/LLC/statement',
  },
  {
    name: 'Secretary Information',
    path: '/setup/LLC/secretary-info',
  },
  {
    name: 'Witness Information',
    path: '/setup/LLC/witness-info',
  },
  {
    name: 'Payment',
    path: '/setup/LLC/payment',
  },
  {
    name: 'Create PIN',
    path: '/setup/LLC/transaction-pin',
  },
];

export const NGOSetup = [
  {
    name: 'NGO Details',
    path: '/setup/NGO/ngo-details',
  },
  {
    name: "Trustee's Information",
    path: '/setup/NGO/trustee-info',
  },
  {
    name: 'Statement Of Issued Share Capital',
    path: '/setup/NGO/statement',
  },
  {
    name: 'Secretary Information',
    path: '/setup/NGO/secretary-info',
  },
  {
    name: 'Witness Information',
    path: '/setup/NGO/witness-info',
  },
  {
    name: 'Payment',
    path: '/setup/NGO/payment',
  },
  {
    name: 'Create PIN',
    path: '/setup/NGO/transaction-pin',
  },
];

export const businessAccountSetup = [
  {
    name: 'Business Details',
    path: '/setup/business-details',
  },
  {
    name: 'Business Ownership',
    path: '/setup/business-ownership',
  },
  {
    name: 'Profile Verification',
    path: '/setup/profile-verification',
  },
  {
    name: 'Bvn Verification',
    path: '/setup/bvn-verification',
  },
  {
    name: 'Director Details',
    path: '/setup/director-details',
  },
];

export const businessAccSetupRest = [
  {
    name: 'Signatories',
    path: '/setup/signatoriess',
  },
  {
    name: 'Transaction Pin',
    path: '/setup/transaction-pin',
  },
];
export const freelancerAccountSetup = [
  {
    name: 'My Details',
    path: '/setup/freelancer-details',
  },
  {
    name: 'Bvn Verification',
    path: '/setup/freelancer/bvn-verification',
  },
  {
    name: 'Transaction Pin',
    path: '/setup/freelancer/transaction-pin',
  },
];

export const siderBarData = [
  {
    id: 0,
    name: 'Naira Account',
    path: '/account',
    icon: <AccountIcon fill="#B4B4B4" />,
    sec: <AccountIcon fill="#F3C948" />,
    hover: <AccountIcon fill="#F3C948" />,
  },
  {
    id: 0,
    name: 'Global Account',
    path: '/global-account',
    icon: <GlobalIcon fill="#B4B4B4" />,
    sec: <GlobalIcon fill="#F3C948" />,
    hover: <GlobalIcon fill="#F3C948" />,
  },
  {
    id: 1,
    name: 'Transactions',
    path: '/transactions',
    icon: <TransactionIcon fill="#B4B4B4" />,
    sec: <TransactionIcon fill="#F3C948" />,
    hover: <TransactionIcon fill="#F3C948" />,
  },
  {
    id: 2,
    name: 'Payments',
    path: '/payments',
    icon: <PaymentIcon fill="#B4B4B4" />,
    sec: <PaymentIcon fill="#F3C948" />,
    hover: <PaymentIcon fill="#F3C948" />,
    sub: [
      {
        id: 1,
        name: 'Transfer',
        path: '/payments/transfer',
      },
      // {
      //   id: 2,
      //   name: 'Pending Payment',
      //   path: '/payments/pending-payment',
      // },
      {
        id: 3,
        name: 'Pay Bills',
        path: '/payments/bills',
      },
    ],
  },
  {
    id: 3,
    name: 'Invoice',
    path: '/invoices',
    icon: <InvoiceIcon fill="#B4B4B4" />,
    sec: <InvoiceIcon fill="#F3C948" />,
    hover: <InvoiceIcon fill="#F3C948" />,
  },
  {
    id: 5,
    name: 'Payment Link',
    path: '/payment-link',
    icon: <LinkIcon fill="#B4B4B4" />,
    sec: <LinkIcon fill="#F3C948" />,
    hover: <LinkIcon fill="#F3C948" />,
  },
  // {
  //   id: 4,
  //   name: "Savings",
  //   path: "/savings",
  //   icon: <SavingsIcon fill="#B4B4B4" />,
  //   sec: <SavingsIcon fill="#F3C948" />,
  //   hover: <SavingsIcon fill="#F3C948" />,
  // },
  // {
  //   id: 5,
  //   name: "POS Management",
  //   path: "/pos-management",
  //   icon: <PosIcon fill="#B4B4B4" />,
  //   sec: <PosIcon fill="#F3C948" />,
  //   hover: <PosIcon fill="#F3C948" />,
  // },
  {
    id: 6,
    name: 'Cards Management',
    path: '/card-management',
    icon: <CardIcon fill="#B4B4B4" />,
    sec: <CardIcon fill="#F3C948" />,
    hover: <CardIcon fill="#F3C948" />,
  },
  // {
  //   id: 7,
  //   name: "Expense Management",
  //   path: "/expense-management",
  //   icon: <ExpenseIcon fill="#B4B4B4" />,
  //   sec: <ExpenseIcon fill="#F3C948" />,
  //   hover: <ExpenseIcon fill="#F3C948" />,
  // },
  // {
  //   id: 8,
  //   name: "Notifications",
  //   path: "/notifications",
  //   icon: <NotificationIcon fill="#B4B4B4" />,
  //   sec: <NotificationIcon fill="#F3C948" />,
  //   hover: <NotificationIcon fill="#F3C948" />,
  // },
  {
    id: 9,
    name: 'Settings',
    path: '/settings',
    icon: <SettingsIcon fill="#B4B4B4" />,
    sec: <SettingsIcon fill="#F3C948" />,
    hover: <SettingsIcon fill="#F3C948" />,
  },
];

export const no = ['1', '2', '3', '4'];

export const activeStyle = {
  backgroundColor: '#D3E0FB',
  alignItems: 'center',
  display: 'flex',
  height: '60px',
  borderRadius: '10px',
  color: '#182444',
  fontWeight: 500,
  fontSize: '14px',
  padding: '0 20px',
};

export const businessType = [
  { value: 'NGO', name: 'NGO' },
  { value: 'BUSINESS-NAME', name: 'Business Name' },
  {
    value: 'LIMITED-LIABILITY-COMPANY',
    name: 'Limited Liability Company (LLC)',
  },
];

export const businessCats = [
  'General merchandise',
  'Trading',
  'Fashion design/tailoring',
  'ICT service',
  'Data analysis',
  'Poultry/livestock farming',
  'Crop production farming/Agro allied service',
  'Hair Stylist/salon',
  'Solar panel installation',
  'Digital marketing',
  'Graphic design',
  'Content creation',
  'Web design',
  'POS agent',
];

export const businessCat = [
  'Finance/Insurance/Real Estate',
  'Mining/Oil & Gas',
  'Services',
  'Farming/Fishing',
  'Health/Pharmacy',
  'Manufacturing',
  'Commerce',
  'Construction/Civil Engineering',
  'Transportation',
  'Paints and Varnishes',
  'Chemicals',
  'Others',
];

export const idTypes = [
  {
    name: 'NIN',
    value: 'NIN',
  },
  { name: 'Driver License', value: 'DRIVER_LICENSE' },
  { name: 'International Passport', value: 'INTERNATIONAL_PASSPORT' },
  { name: "Voter's Card License", value: 'VOTERS_CARD' },
];

export const utilityBillTypes = [
  {
    name: 'Electricity Bill',
    value: 'Electricity Bill',
  },
  { name: 'Water Bill', value: 'Water Bill' },
  { name: 'Waste Management Bill', value: 'Waste Management Bill' },
  { name: 'House Rent Receipt', value: 'House Rent Receipt' },
];

export const preferredBank = ['9PSB'];
export const countries = [
  'Nigeria',
  'Ghana',
  'Egypt',
  'SouthAfrica',
  'COte’d Ivoire',
  'Cameroun',
  'Liberia',
];
export const ids = [
  {
    name: 'NIN',
    value: 'NIN',
  },
  { name: 'Driver License', value: 'DRIVER_LICENSE' },
  { name: "Voter's Card License", value: 'VOERS_CARD' },
];

export const setup = [
  'Type of Transfer',
  'Payment Detail',
  'Review Payment',
  'Make Payment',
];

export const account = [
  'Current Account ',
  'Operational Account',
  'Salary Account',
];

export const TableHeader = ['', 'From', 'To', 'Purpose', 'Amount', '', ''];
export const TableHeaders = ['From', 'To', 'Purpose', 'Amount', '', ''];

export const requestAccount = [
  {
    acc: 'Current Account',
    amount: '3,000,000,000',
  },
  {
    acc: 'Logistics Account',
    amount: '3,000,000,000',
  },
  {
    acc: 'Salary Account',
    amount: '3,000,000,000',
  },
];

export const cardType = [
  { label: 'Virtual', value: 'virtual' },
  // { name: 'Physical', value: 'physical' },
];

export const currency = ['USD'];
export const summary = [
  {
    id: 1,
    title: 'Kelon Designs',
    bank: 'Providus bank',
    accNum: '0123456789',
    tag: 'Main Acc.',
    fees: '₦ 4,050,030. 00',
  },
  {
    id: 2,
    title: 'Operations Account',
    bank: 'Providus bank',
    accNum: '0123456789',
    tag: 'Sub Acc.',
    fees: '₦ 1,050,470. 00',
  },
  {
    id: 3,
    title: 'Expenses Account',
    bank: 'Providus bank',
    accNum: '0123456789',
    tag: 'Sub Acc.',
    fees: '₦ 3,650,020. 00',
  },
];

export const quickActions = [
  {
    id: '1',
    icon: <TransferIcon />,
    title: 'Transfer',
    path: '/payments/transfer',
  },
  {
    id: '2',
    icon: <PayBillIcon />,
    title: 'Pay Bills',
    path: '/payments/bills',
  },
  {
    id: '3',
    icon: <BuyAirtime />,
    title: 'Buy Airtime',
    path: '/payments/bills/airtime',
  },
];

export const reportFilter = ['90 days', '30 days', '7 days'];
export const transactionHeader = [
  'Date',
  'Account',
  'Transaction Type',
  'Amount',
  'Balance Before',
  'Balance After',
  'Status',
  '',
];

export const paymentLinkHeader = [
  'Date',
  'Payment Source',
  'Transaction Type',
  'Amount (NGN)',
  'Status',
  '',
];
export const viewpaymentLinkHeader = [
  'Date',
  'Link Name',
  'Link Type',
  'Amount (NGN)',
  'Links',
  'Status',
  '',
];
export const transactionData = [
  {
    name: 'Odogwu Kelon',
    date: 'Dec 10,2021 10:40pm',
    ref: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    account: '10.00',
    successful: true,
  },
  {
    name: 'Odogwu Kelon',
    date: 'Dec 10,2021 10:40pm',
    ref: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    account: '10.00',
    successful: false,
  },
  {
    name: 'Odogwu Kelon',
    date: 'Dec 10,2021 10:40pm',
    ref: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    account: '10.00',
    successful: true,
  },
  {
    name: 'Odogwu Kelon',
    date: 'Dec 10,2021 10:40pm',
    ref: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    account: '10.00',
    successful: false,
  },
  {
    name: 'Odogwu Kelon',
    date: 'Dec 10,2021 10:40pm',
    ref: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    account: '10.00',
    successful: true,
  },
  {
    name: 'Odogwu Kelon',
    date: 'Dec 10,2021 10:40pm',
    ref: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    account: '10.00',
    successful: false,
  },
];
export const createSubAccount = [
  {
    id: 1,
    acc: 'Savings',
    desc: 'Description',
  },
  {
    id: 2,
    acc: 'Salary',
    desc: 'Description',
  },
  {
    id: 3,
    acc: 'Tax',
    desc: 'Description',
  },
  {
    id: 4,
    acc: 'Other',
    desc: 'Description',
  },
];

export const acc = ['Current Account', 'Operational Account', 'Salary Account'];
export const serviceProvider = [
  {
    id: 1,
    name: 'MTN',
    icon: '/assets/mtn.svg',
  },
  {
    id: 2,
    name: 'GLO',
    icon: '/assets/glo.svg',
  },
  {
    id: 3,
    name: '9MOBILE',
    icon: '/assets/9MobileNg.svg',
  },
  {
    id: 4,
    name: 'AIRTEL',
    icon: '/assets/airtel.svg',
  },
];

export const electricityProvider = [
  {
    id: '1',
    name: 'EKEDC',
    icon: '/assets/ekedc.svg',
  },
  {
    id: '2',
    name: 'ie',
    icon: '/assets/ie.svg',
  },
  {
    id: '3',
    name: 'PHD',
    icon: '/assets/ph.svg',
  },
  {
    id: '4',
    name: 'IBEDC',
    icon: '/assets/ibedc.svg',
  },
];
export const biller = [
  'DSTV',
  'GOTV',
  'KWESE TV',
  'TSTV',
  'TREND TV',
  'STARTIME',
  'PRIME TV',
];

export const products = [
  {
    id: 1,
    name: 'Asia Add-on',
    amount: '200.00',
  },
  {
    id: 2,
    name: 'Standalone',
    amount: '200.00',
  },
  {
    id: 3,
    name: 'Office',
    amount: '200.00',
  },
  {
    id: 4,
    name: 'Compact',
    amount: '8050',
  },
  {
    id: 5,
    name: 'Compact',
    amount: '200.00',
  },
  {
    id: 6,
    name: 'Compact',
    amount: '200.00',
  },
  {
    id: 7,
    name: 'Compact',
    amount: '200.00',
  },
];

export const dataProduct = [
  '1GB Data (1 Day)',
  '1TB Mobile Data (1 Year)',
  '240 GB Data (30 Days)',
  '40 GB Data (30 Days)',
];
export const electProvider = [
  'Abuja Disco',
  'BEDC',
  'EKEDC',
  'ENUGU DISCO',
  'IBEDC',
  'JEDC',
  'KAEDCO',
  'KANO ELECTRICITY',
  'LUMOS',
  'PORTHARCOURTH DISCO',
];

export const tranFilter = ['All Transactions', 'Inflow', 'Payout'];
export const dateFilter = ['Last  30 Days', 'Last 60 Days', 'Last 90 Days'];
export const transHeader = [
  'Date',
  'Account',
  'Description',
  'Payment Type',
  'Amount',
  'Balance Before',
  'Balance Before',
  '',
];

export const categories = [
  { name: 'All Categories', value: '' },
  { name: 'Airtime', value: 'AIRTIME' },
  { name: 'Data', value: 'DATA' },
  { name: 'Cable', value: 'CABLE' },
  { name: 'Electricity', value: 'ELECTRICITY' },
  { name: 'Transfer', value: 'TRANSFER' },
  { name: 'Inflow', value: 'CREDIT' },
  { name: 'Outflow', value: 'DEBIT' },
  { name: 'Saving', value: 'SAVING' },
  { name: 'Card Funding', value: 'CARD_FUNDING' },
  { name: 'Card Unloading', value: 'CARD_UNLOADING' },
];
export const transType = [
  { name: 'All Categories', value: '' },
  {
    name: 'OutFlow',
    value: 'DEBIT',
  },
  {
    name: 'Inflow',
    value: 'CREDIT',
  },
];

export const tranHeader = [
  'Date',
  'Payment Type',
  'Amount',
  'Balance Before',
  'Balance After',
  '',
];
export const transData = [
  {
    date: '17 Oct,2023',
    time: '10:32 am',
    account: 'Operation Acc',
    des: 'Testing',
    type: 'Inflow',
    amount: ' 290,000',
    balanceB: '490,000',
    balanceBefore: '7290,000',
  },
  {
    date: '17 Oct,2023',
    time: '10:32 am',
    account: 'Operation Acc',
    des: 'Testing',
    type: 'Outflow',
    amount: ' 290,000',
    balanceB: '490,000',
    balanceBefore: '7290,000',
  },
  {
    date: '17 Oct,2023',
    time: '10:32 am',
    account: 'Operation Acc',
    des: 'Testing',
    type: 'Outflow',
    amount: ' 290,000',
    balanceB: '490,000',
    balanceBefore: '7290,000',
  },
  {
    date: '17 Oct,2023',
    time: '10:32 am',
    account: 'Operation Acc',
    des: 'Testing',
    type: 'Outflow',
    amount: ' 290,000',
    balanceB: '490,000',
    balanceBefore: '7290,000',
  },
  {
    date: '17 Oct,2023',
    time: '10:32 am',
    account: 'Operation Acc',
    des: 'Testing',
    type: 'Outflow',
    amount: ' 290,000',
    balanceB: '490,000',
    balanceBefore: '7290,000',
  },
];
export const pendingPaymentHeader = [
  'Date',
  'From',
  'To',
  'Purpose',
  'Amount',
  '',
];
export const pendingPaymentData = [
  {
    data: '17 Oct,2023',
    time: '10:32 am',
    from: 'Kelon Design - Operation Acc',
    name: 'Odogwu Kelon',
    acc: '0093456782  Access Bank',
    purpose: 'Monthly Operation',
    amount: '290,000',
  },
  {
    data: '17 Oct,2023',
    time: '10:32 am',
    from: 'Kelon Design - Operation Acc',
    name: 'Odogwu Kelon',
    acc: '0093456782  Access Bank',
    purpose: 'Monthly Operation',
    amount: '290,000',
  },
  {
    data: '17 Oct,2023',
    time: '10:32 am',
    from: 'Kelon Design - Operation Acc',
    name: 'Odogwu Kelon',
    acc: '0093456782  Access Bank',
    purpose: 'Monthly Operation',
    amount: '290,000',
  },
];
export const accountBal = [
  {
    acc: 'Current Account',
    amount: '3,000,000,000',
  },
  {
    acc: 'Logistics Account',
    amount: '3,000,000,000',
  },
  {
    acc: 'Salary Account',
    amount: '3,000,000,000',
  },
];
export const duration = ['1', '2', '3', '4', '5', '6', '7'];

export const payment = [
  'Make single Payment',
  'Transfer between Accounts',
  'Make a Bulk Transfer',
];

export const bank = ['Access ', 'GTB', 'Union'];

export const colors = [
  '#292D32',
  '#200E32',
  '#132447',
  '#30416C',
  '#F4F4F4',
  '#000AFF',
  '#9DA0FB',
  '#EBAA03',
  '#FC0909',
  '#A60838',
  '#7B47CA',
  '#045454',
  '#01FFFF',
  '#29FF06',
  '#01771B',
  '#790404',
  '#FAFF04',
  '#8B8D03',
  '#FE6108',
  '#6B2802',
  '#A96060',
  '#04484D',
  '#330205',
  '#A97660',
  '#BEFE08',
  '#000000',
  '#707070',
  '#D9D9D9',
];

export const Colors = ['#F1F6FF', '#545B6A', '#0F172B'];

export const TransactionHistory = [
  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: true,
  },

  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: true,
  },

  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: false,
  },

  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: true,
  },

  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: false,
  },
  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: true,
  },
  {
    name: 'Netflix',
    date: 'Dec 10,2021 10:40pm',
    transId: 'FM2022-TRANS-IPDE0001',
    amount: '35,000',
    fee: '10.00',
    successful: true,
  },
];
export const savingsData = [
  {
    img: '/assets/sf.png',
    name: 'Santorini Trip',
    amount: '4,000,000',
    plan: 'daily',
    day: '31',
    percent: '4.5',
    interest: '420,000',
  },
  {
    img: 'assets/sf.png',
    name: 'Santorini Trip',
    amount: '4,000,000',
    plan: 'daily',
    day: '31',
    percent: '4.5',
    interest: '420,000',
  },
  {
    img: 'assets/fd.png',
    name: 'Lekki 4bed',
    amount: '3,000,000',
    totalDays: '365',
    plan: 'fixed',
    day: '46',
    percent: '4.5',
    interest: '1,420,000',
  },
];

export const transactionHistory = [
  {
    title: 'Top Up',
    name: 'Daily Savings',
    amount: '80,000.00',
    date: 'Oct 17, 2023',
  },
  {
    title: 'Withdrawal',
    name: 'Daily Savings',
    amount: '80,000.00',
    date: 'Dec 17, 2023',
  },
  {
    title: 'Top Up',
    name: 'Daily Savings',
    amount: '80,000.00',
    date: 'Oct 17, 2023',
  },
  {
    title: 'Top Up',
    name: 'Fixed Savings',
    amount: '80,000.00',
    date: 'Oct 17, 2023',
  },
  {
    title: 'Withdrawal',
    name: 'Fixed Savings',
    amount: '80,000.00',
    date: 'Dec 17, 2023',
  },
  {
    title: 'Top Up',
    name: 'Fixed Savings',
    amount: '80,000.00',
    date: 'Oct 17, 2023',
  },
  {
    title: 'Top Up',
    name: 'Fixed Savings',
    amount: '80,000.00',
    date: 'Oct 17, 2023',
  },
];

export const invoiceHisHeader = [
  'ID',
  'Name',
  'Status',
  'Email',
  'Issued Date',
  '',
];

export const invoiceHistory = [
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Paid',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Sent',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Part Payment',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Overdue',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Overdue',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Overdue',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Overdue',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Overdue',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    status: 'Overdue',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
];

export const updateStatus = [
  // {
  //   id: 1,
  //   status: 'Sent',
  //   value: 'SENT',
  //   info: 'This invoice has been sent to the client',
  // },
  {
    id: 2,
    status: 'Paid',
    value: 'PAID',
    info: 'We have received payment for this invoice',
  },
  {
    id: 3,
    status: 'Partial Payment',
    value: 'PARTIAL PAYMENT',
    info: 'We have received part payment for this invoice',
  },
  // {
  //   id: 4,
  //   status: 'Overdue',
  //   value: 'OVERDUE',
  //   info: 'We have not received  payment for this invoice, due date has elapsed.',
  // },
];
export const InvoiceDraftHeader = ['ID', 'Name', 'Email', 'Date', ' '];
export const invoiceDraft = [
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
  {
    id: '001111111',
    name: 'Adetola Adele',
    email: 'adetolaadele@gmail',
    date: '11/20/2023',
  },
];

export const subscriptionPlans = [
  {
    name: 'Flex Plan',
    info: 'Pay as you use ,businesses pay per invoice created.',
    price: '15',
    pay: 'You pay ₦15/invoice',
  },
  {
    name: 'Starter Bundle',
    info: '50 invoice bundle businesses can buy this bundle for 500 Naira.',
    price: '500',
    pay: 'You pay ₦500/50 invoices',
  },
  {
    name: 'Growth Bundle',
    info: '100 invoice bundle businesses can buy this bundle for 800 Naira.',
    price: '800',
    pay: 'You pay ₦800/100 invoices',
  },
  {
    name: 'Bulk Pro Bundle',
    info: '500 invoice bundle businesses can buy this bundle for 3800 Naira',
    price: '3800',
    pay: 'You pay ₦3800/500 invoices',
  },
];

export const preChoice = [
  'Attach POS to an existing account',
  'Create new POS account',
];

export const preChoiceV2 = {
  attach: 'Attach POS to an existing account',
  create: 'Create new POS account',
};

export const PosBal = [
  {
    acc: 'Current Account',
    amount: '3,000,000,000',
  },
  {
    acc: 'Logistics Account',
    amount: '3,000,000,000',
  },
  {
    acc: 'Salary Account',
    amount: '3,000,000,000',
  },
];

export const paymentPlan = ['Pay ₦110,000 at once', 'Pay 500 for 220days'];

export const PosHistory = [
  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '10.00',
    successful: true,
  },

  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '35,000',
    successful: true,
  },

  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '35,000',
    successful: false,
  },

  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '35,000',
    successful: true,
  },

  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '35,000',
    successful: true,
  },

  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '35,000',
    successful: false,
  },

  {
    name: 'Segun Thompson',
    date: 'Dec 10,2021 10:40pm',
    summary: 'Groceries',
    transaction_Ref: 'POS-00000000..',
    amount: '35,000',
    successful: true,
  },
];

export const PosHeader = [
  'Transaction',
  'Summary',
  'Transaction Ref',
  'Amount',
  ' ',
];
export const permission = [
  'Update Invoice Status',
  'Create Invoice',
  'Generate Receipt',
];

export const StaffHeader = ['Name', 'Email', 'Phone Number', ' '];

export const notifications = {
  sendEmail: 'Email Notification',
  sendSms: 'Push Notification',
  sendPushNotification: 'Sms Notification',
};

export const securityTab = [
  'Change Password',
  'Change Transaction Pin',
  'Two Factor Authentication',
];

export const languageTab = ['Language', 'Region & Time Zone'];

export const languages = ['English', 'French', 'Spanish', 'Italian'];

export const timeZone = [
  '(UTC-08:00) Pacific Time (US & Canada)',
  '(UTC+01:00) Central European Time (CET)',
  '(UTC+02:00) Eastern European Time (EET)',
  '(UTC+00:00) Greenwich Mean Time (GMT)',
];

export const privacy = [
  'Data Export',
  'Data Deletion',
  'Data Usage Policy',
  'Terms & Conditions',
];

export const download = [
  'Data Export',
  'Data Deletion',
  'Data Usage Policy',
  'Terms & Conditions',
];

export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const recurringInvoice = [
  { value: 'WEEKLY', name: 'Weekly' },
  { value: 'BI-WEEKLY', name: 'Bi-Weekly' },
  {
    value: 'MONTHLY',
    name: 'Monthly',
  },
  {
    value: 'OFF',
    name: 'Off',
  },
];

export const paymentCards = [
  '/assets/verve.png',
  '/assets/mastercard.png',
  '/assets/visa.png',
];

export const inflowFilters = [
  {
    id: 1,
    name: 'Yesterday',
    value: '1',
  },
  {
    id: 2,
    name: 'Last Week',
    value: '7',
  },
  {
    id: 3,
    name: 'Last 14 days',
    value: '14',
  },
  {
    id: 4,
    name: 'Last 30 days',
    value: '30',
  },
];

export const globalAccountSetUp = [
  {
    title: 'Get a personal USD account',
    desc: 'Effortlessly set up your personal USD account. Ideal for managing international transactions, and receiving gig payments and salaries globally— all from Nigeria. Get started today!',
    img1: '/assets/Dollar_Coin.svg',
    img2: '/assets/Dollar_Coin-1.svg',
    img3: '/assets/Dollar-3.svg',
  },
  {
    title: 'Get a personal Euro account',
    desc: 'Effortlessly set up your personal EURO account with ease. Efficiently manage your euros with convenient transactions and savings, all from Nigeria. Get started today!',

    img1: '/assets/Euro-Coin.svg',
    img2: '/assets/Euro-Coin-1.svg',
    img3: '/assets/Euro3.svg',
  },
];

export const globalAccQuickActions = [
  {
    heading: 'Send Money',
    content: 'Send to Local Currency Account',
    img: '/assets/naira.png',
  },
  {
    heading: 'Conversion Rate',
    content: 'Check our conversion rates for today!',
    img: '/assets/3dicons1.png',
  },
  {
    heading: 'Recieve Int`l Payment',
    content: 'Receive money from a Non - US country',
    img: '/assets/recievepayment.svg',
  },
];
export const globalAccHeader = [
  'Date',
  'Amount',
  // 'Description',
  'Transaction Type',
  'Status',
  '',
];

export const accKycInfo = [
  {
    icon: '/assets/money-send.svg',
    text: 'Send money globally',
  },
  {
    icon: '/assets/send-2.svg',
    text: 'Receive money from anywhere',
  },
  {
    icon: '/assets/arrange-square.svg',
    text: 'Swap between currencies',
  },
];

export const band = [
  { label: 'band', value: 'band' },
  { label: 'band', value: 'band' },
];

export const employmentStatus = [
  { label: 'unemployed', value: 'unemployed' },
  { label: 'employed', value: 'employed' },
];

export const accDesignation = [
  { label: 'unemployed', value: 'unemployed' },
  { label: 'employed', value: 'employed' },
];

export const incomeSource = [
  { label: 'unemployed', value: 'unemployed' },
  { label: 'employed', value: 'employed' },
];

export const occupation = [
  { label: 'unemployed', value: 'unemployed' },
  { label: 'employed', value: 'employed' },
];

export const globalAccountInfo = [
  {
    title: 'The minimum deposit you can receive is $2',
    text: ' Micro deposits (amounts less than $1) used for account confirmation can still be received.',
    img: '/assets/green-dollar.png',
  },
  {
    title: 'The maximum daily deposit is $5,000',
    text: 'Micro deposits (amounts less than $1) used for account confirmation can still be received.',
    img: '/assets/Dollar_Coin-1.svg',
  },
];

export const categoryTypes = [
  { name: 'All Categories', value: '' },
  {
    name: 'General',
    value: 'GENERAL',
  },
  {
    name: 'Dynamic',
    value: 'DYNAMIC',
  },
];

export const kycIdType = [
  {
    label: 'Driver`s license',
    value: 'NIGERIAN_DRIVERS_LICENSE',
  },
  {
    label: 'International Passport',
    value: 'NIGERIAN_INTERNATIONAL_PASSPORT',
  },
];
