import { useEffect, useState } from 'react';
import { Flex, Text, Box, Image, Skeleton } from '@chakra-ui/react';
import { BiCopy } from 'react-icons/bi';
import useCustomToast from '../../../utils/notifications';
import { useGetPaymentLinkTotal } from '../../../services/query/paymentLink';

const DetailCards = ({
  linkDetails,
  isLoading,
  settings,
  isSettings,
  reference,
}) => {
  const { successToast, errorToast } = useCustomToast();
  const linkToCopy = linkDetails?.url;

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        successToast('Link copied to clipboard!');
      })
      .catch((err) => {
        errorToast('Failed to copy');
      });
  };
  const {
    mutate,
    data,
    isLoading: isLoadingAmountReceived,
  } = useGetPaymentLinkTotal({
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (settings?.data) {
      mutate({
        id: settings?.data?.accountId,
        status: 'SUCCESSFUL',
        reference: reference,
      });
    }
  }, [settings?.data?.accountId]);

  return (
    <>
      {' '}
      <Flex
        mt="40px"
        color="#0F172B"
        overflowX="scroll"
        className="no_scroller"
        scrollBehavior="smooth"
        transition="0.5s ease-in-out"
        gap="24px"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none', // Hides the scrollbar in WebKit browsers (Chrome, Safari)
          },
          '&': {
            '-ms-overflow-style': 'none', // Hides the scrollbar in Internet Explorer and Edge
            'scrollbar-width': 'none', // Hides the scrollbar in Firefox
          },
        }}
      >
        <Skeleton isLoaded={!isLoading}>
          <Flex flexDir="column" minW={{ base: '350px', md: '375px' }}>
            <Flex
              flexDir="column"
              h="10rem"
              borderRadius="8px"
              bg="#FFFFFF"
              color="#667185"
              border="1px solid #ECEFF2"
              pt="33px"
              pb="27px"
              pl="22px"
              pr="40px"
            >
              <Box pb="6px" borderBottom="1px solid #1137703D">
                <Text fontSize={'14px'} fontWeight={500}>
                  Link Name
                </Text>
              </Box>
              <Flex pt="28px" justifyContent="space-between">
                <Flex gap="24px">
                  {/* <Box
                  bg="#000"
                  h="fit-content"
                  px="6px"
                  py="7px"
                  borderRadius="4px"
                >
            
                  <VscLink fontSize="20px" color="#F3C948" />
                </Box>  */}
                  <Image src="/assets/ServiceLogo.svg" />
                  <Box color="#0F172B">
                    {' '}
                    <Text
                      fontWeight={500}
                      fontSize={{ base: '15px', md: '18px' }}
                      lineHeight={'20.47px'}
                      my={'auto'}
                    >
                      {linkDetails?.name}
                    </Text>
                    <Text pt="5px" fontWeight={500}>
                      <span style={{ color: '#FF9D16' }}>Amount:</span> {''}₦
                      {Number(linkDetails?.amount)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Skeleton>
        <Skeleton isLoaded={!isSettings}>
          <Flex flexDir="column" minW={{ base: '350px', md: '375px' }}>
            <Flex
              flexDir="column"
              h="10rem"
              borderRadius="8px"
              bg="#FFFFFF"
              color="#667185"
              border="1px solid #ECEFF2"
              pt="33px"
              pb="27px"
              pl="22px"
              pr="40px"
            >
              <Box pb="6px" borderBottom="1px solid #1137703D">
                <Text fontWeight={500}>Amount Received</Text>
              </Box>
              <Flex pt="28px" justifyContent="space-between">
                <Flex gap="24px">
                  <Box
                    bg="#FF9D16"
                    h="fit-content"
                    px="6px"
                    py="7px"
                    borderRadius="4px"
                  >
                    <Image src="/assets/MoneyIn.svg" />
                  </Box>
                  <Text
                    color="#22292F"
                    fontWeight={600}
                    fontSize={{ base: '18px', md: '24px' }}
                  >
                    {data ? (
                      <>
                        ₦
                        {Number(data?.data)?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        }) || '0.00'}
                      </>
                    ) : (
                      '0'
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Skeleton>
        <Skeleton isLoaded={!isLoading}>
          <Flex
            flexDir="column"
            minW={{ base: '350px', md: '375px' }}
            mr="24px"
            display={{ base: 'none', md: 'block' }}
          >
            <Flex
              flexDir="column"
              h="10rem"
              borderRadius="8px"
              bg="#FFFFFF"
              color="#667185"
              border="1px solid #ECEFF2"
              pt="33px"
              pb="27px"
              pl="22px"
              pr="40px"
            >
              <Box pb="6px" borderBottom="1px solid #1137703D">
                <Text fontSize={'14px'} fontWeight={500}>
                  Payment Link
                </Text>
              </Box>
              <Flex pt="28px" gap="14px">
                <Flex
                  gap="14px"
                  py="12px"
                  px="1rem"
                  border="1px solid #11377040"
                  borderRadius="4px"
                  fontSize="12px"
                  color="#8E8E8E"
                >
                  <Text>
                    {linkDetails?.url?.length > 20
                      ? `${linkDetails.url.slice(0, 20)}...`
                      : linkDetails?.url}
                  </Text>
                </Flex>
                <Flex
                  border="1px solid #D5DDE2"
                  px="10px"
                  py="4px"
                  bgColor="#667185"
                  borderRadius="6px"
                  alignItems="center"
                  fontWeight={500}
                  color="#ffff"
                  fontSize="14px"
                  gap="12px"
                  cursor="pointer"
                  onClick={handleCopyLink}
                >
                  <Text>Copy</Text>

                  <BiCopy fontSize="20px" />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Skeleton>
      </Flex>
      {/* for the mobile veiw */}
      <Skeleton isLoaded={!isLoading}>
        <Flex
          flexDir="column"
          minW={{ base: '100%', md: '375px' }}
          maxW={{ base: '500px', md: 'unset' }}
          // mr="24px"
          mt="24px"
          display={{ base: 'block', md: 'none' }}
        >
          <Flex
            flexDir="column"
            h="10rem"
            borderRadius="8px"
            bg="#FFFFFF"
            color="#667185"
            border="1px solid #ECEFF2"
            pt="33px"
            pb="27px"
            pl="22px"
            pr="40px"
          >
            <Box pb="6px" borderBottom="1px solid #1137703D">
              <Text fontSize={'14px'} fontWeight={500}>
                Payment Link
              </Text>
            </Box>
            <Flex pt="20px" gap="14px">
              <Flex
                gap="14px"
                py="12px"
                px="1rem"
                border="1px solid #11377040"
                borderRadius="4px"
                fontSize="12px"
                color="#8E8E8E"
                w={'100%'}
              >
                <Text>
                  {linkDetails?.url?.length > 20
                    ? `${linkDetails.url.slice(0, 20)}...`
                    : linkDetails?.url}
                </Text>
              </Flex>
              <Flex
                border="1px solid #D5DDE2"
                px="10px"
                py="4px"
                bgColor="#667185"
                borderRadius="6px"
                alignItems="center"
                fontWeight={500}
                color="#ffff"
                fontSize="14px"
                gap="12px"
                cursor="pointer"
                onClick={handleCopyLink}
              >
                <Text>Copy</Text>

                <BiCopy fontSize="20px" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Skeleton>
    </>
  );
};
export default DetailCards;
