export const PUBLIC_PATHS = {
  LOGIN: '/login',
  FORGOT_PASS: '/forgot-password',
  VERIFY_PASS_OTP: '/verify-password-otp',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASS: '/reset-password',
  REG_ONE: '/register-step-1',
  REG_TWO: '/register-step-2',
  INVOICE_PREVIEW: '/invoices',
  INVOICE_PAYMENT: '/invoices-payment',
  PAYMENT_LINK_PREVIEW: '/payment',
  PAYMENT_LINK_REVIEW: '/payment/payment-review',
  CONFIRM_PAYMENT: '/payment/confirm-payment',
  CONFIRM_INVOICE_PAYMENT: '/payment/confirm-invoice-payment',
  INVOICE_PAYMENT_REVIEW: '/payment/invoice-payment-review',
};

export const PRIVATE_PATHS = {
  CREATE_ACCOUNT: '/create-account/step-1',
  CREATE_ACCOUNT_STEP_2: '/create-account/step-2',
  ACCOUNT: '/account',
  CARDS_MANAGEMENT: '/card-management',
  EXPENSE_MANAGEMENT: '/expense-management',
  POS_MANAGEMENT: '/pos-management',
  PHYSICAL_POS: '/pos-management/physical-pos/create',
  REQUEST_POS: '/pos-management/request-pos/create',
  STAFF_MANAGEMENT: '/pos-management/staff-management/create',
  VIRTUAL_POS: '/pos-management/virtual-pos/create',
  INVOICE: '/invoices',
  CREATE_INVOICE: '/invoices/create',
  SAVE_INVOICE: '/invoices/save',
  INVOICE_DRAFT: '/invoices/draft',
  EDIT_DRAFT: '/invoices/edit-draft',
  SUBSCRIPTION: '/invoices/subscription',
  INVOICE_SETTINGS: '/invoices/settings',
  VIEW_INVOICE: '/invoices/view-invoice',
  SAVINGS: '/savings',
  PAYMENTS: '/payments/transfer',
  SINGLE: '/payments/transfer/single',
  EMAIL: '/payments/transfer/email',
  BTW: '/payments/transfer/btw',
  REVIEW_PAYMENT_TRANSFER: '/payments/transfer/:type/review-payment',
  MAKE_PAYMENT_TRANSFER: '/payments/transfer/:type/make-payment',
  PENDING_PAYMENTS: '/payments/pending-payment',
  PAY_BILLS: '/payments/bills',
  PAY_BILLS_AIRTIME: '/payments/bills/airtime',
  PAY_BILLS_DATA: '/payments/bills/data',
  PAY_BILLS_ELECTRICITY: '/payments/bills/electricity',
  PAY_BILLS_CABLE: '/payments/bills/cable',
  REVIEW_PAYMENT: '/payments/bills/:type/review-payment',
  MAKE_PAYMENT: '/payments/bills/:type/make-payment',
  SETTINGS: '/settings',
  PERSONAL_INFO: '/settings/personal-info/create',
  NOTI_PREFERENCE: '/settings/noti-preference/create',
  SECURITY: '/settings/security/create',
  LANGUAGES: '/settings/languages/create',
  PRIVACY: '/settings/privacy/create',
  SUPPORT: '/settings/support/create',
  ACCOUNT_UPGRADE: '/settings/account-upgrade/create',
  METER_DETAILS: '/settings/meter-details',
  UTILITY_BILL_DETAILS: '/settings/utility-bill-details',
  DELETE_ACCOUNT: '/delete-account',
  REFERRAL: '/settings/referral',
  NOTIFICATIONS: '/notifications',
  TRANSACTIONS: '/transactions',
  BUSINESS_DETAILS: '/setup/business-details',
  BUSINESS_OWNERSHIP: '/setup/business-ownership',
  PROFILE_VERIFICATION: '/setup/profile-verification',
  BVN_VERIFICATION: '/setup/bvn-verification',
  DIRECTOR_DETAILS: '/setup/director-details',
  SIGNATORIES: '/setup/signatoriess',
  INVITE_SIGNATORIES: '/setup/signatoriess/invite-signatoriess/create',
  TRANSACTION_PIN: '/setup/transaction-pin',

  FREELANCER_DETAILS: '/setup/freelancer-details',
  FREELANCER_OWNERSHIP: '/setup/freelancer-ownership',
  FREELANCER_BVN_VERIFICATION: '/setup/freelancer/bvn-verification',
  UTILITY_BILL: '/setup/utility-bill',
  FREELANCER_TRANSACTION_PIN: '/setup/freelancer/transaction-pin',

  // BUSINESS_SETUP

  BUSINESS_BUSINESS_DETAILS: '/setup/business/business-details',
  BUSINESS_DIRECTOR_INFO: '/setup/business/director-info',
  BUSINESS_PROFILE_VERIFICATION: '/setup/business/profile-verification',
  BUSINESS_PAYMENT: '/setup/business/payment',
  BUSINESS_PIN: '/setup/business/transaction-pin',

  // LLC SETUP

  LLC_BUSINESS_DETAILS: '/setup/LLC/business-details',
  LLC_DIRECTOR_INFO: '/setup/LLC/director-info',
  LLC_STATEMENT: '/setup/LLC/statement',
  LLC_SECRETARY: '/setup/LLC/secretary-info',
  LLC_WITNESS: '/setup/LLC/witness-info',
  LLC_PAYMENT: '/setup/LLC/payment',
  LLC_PIN: '/setup/LLC/transaction-pin',

  // NGO SETUP

  NGO_DETAILS: '/setup/NGO/ngo-details',
  NGO_TRUSTEE_INFO: '/setup/NGO/trustee-info',
  NGO_STATEMENT: '/setup/NGO/statement',
  NGO_SECRETARY: '/setup/NGO/secretary-info',
  NGO_WITNESS: '/setup/NGO/witness-info',
  NGO_PAYMENT: '/setup/NGO/payment',
  NGO_PIN: '/setup/NGO/transaction-pin',

  PENDING_APPROVAL: '/setup/pending-approval',

  //Global Account:
  GLOBAL_ACCOUNT: '/global-account',
  GLOBAL_ACCOUNT_SIGN_UP: '/global/set-up',
  SEND_MONEY: '/global-account/send-money',
  GLOBAL_ACC_KYC: '/global/set-up/verify-kyc',
  GLOBAL_ACC_KYC_STEP_2: '/global/set-up/verify-kyc/step-2',
  GLOBAL_ACC_MORE_INFO: '/global-account/view-more',
  GLOBAL_ACC_VIEW_TRANSACTIONS: '/global-account/view-transactions', //Payment Link
  PAYMENT_LINK: '/payment-link',
  PAYMENT_LINK_SETTINGS: '/payment-link/settings',
  VIEW_PAYMENT_LINK: '/payment-link/view-payment-link',
  TRANSACTION_DETAILS: '/payment-link/transaction-details/:reference/:uuid',
};
